import { generateGuid, getRandomInt } from '../helpers/utils';
import { Event } from '../types';

const titles = [
  'Machine Learning',
  'Kubernetes',
  'Networking',
  'Operating Systems',
  'Agile',
  'Fargate',
  'Mobile',
  'Web',
  'Ethical Hacking',
  'javascript',
  'python',
  'IOT',
  'Telecoms',
  'Testing',
  'Migration',
  'Bitcoin',
  'Blockchain',
  'Gaming',
  'Virtual Reality',
  'Java',
  'Rust',
  'GoLang',
  'Docker',
  'Continous Depoyment',
  'Devops',
  'Hackathon',
  'Augmented Reality',
  'Debugging',
];

const types = [
  'ML',
  'Analytics',
  'Serverless',
  'Networking',
  'Database',
  'Testing',
  'AI',
];

const link = 'https://source.unsplash.com/category/nature';

let date = new Date();

const levels = [100, 200, 300, 400];

const organizers = ['John Doe', 'Jane Doe', 'Bob Smith'];
const segments = ["Automotive", "HCLS", "Manufacturing"];
const personas = ["C-level", "Architects", "Security"];

const events: Event[] = titles
  .map((title, i) => {
    date.setDate(i + 1);
    return {
      id: generateGuid(),
      title,
      start: date.toISOString(),
      name: title,
      type: "webinar",
      segments: segments[i % segments.length],
      personas: personas[i % personas.length],
      categories: types[i % types.length],
      landingPageUrl: "aws.amazon.com",
      startDate: date.toISOString(),
      endDate: date.toISOString(),
      region: "France",
      level: "100"
    };
  })
  .reverse();

export const getEventById = (eventID: string): Event | undefined => {
  return events.find((event) => event.id === eventID);
};

export const getColorForType = (type: string) => {
  const colors = ['red', 'orange', 'yellow', 'green', 'blue', 'purple'];

  const index = types.indexOf(type);

  return colors[index % colors.length];
};

export default events;
