import React, { useState, useEffect, useContext } from 'react';
import { Modal, TableProps, Link, StatusIndicator, Button, Table, Pagination, Alert, Box } from '@amzn/awsui-components-react/polaris';
import { StatusIndicatorProps } from '@amzn/awsui-components-react/polaris/status-indicator';
import { CampaignInvite, InvitationStatus, ContactInvitation } from '../../types';
import { API, graphqlOperation } from 'aws-amplify';
import { getContactsInvitationsForCampaign } from '../../graphql/queries';
import { resendInvitation } from '../../graphql/mutations';
import AlertContext from '../../contexts/AlertContext';

type Props = {
  onDismiss: () => void;
  invitation: CampaignInvite;
};

const InvitationModal = (props: Props) => {
  const { onDismiss, invitation } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState<ContactInvitation[]>([]);
  const [pagesCount, setPagesCount] = useState(10);
  const [visible, setVisible] = React.useState(false);
  const showAlert = useContext(AlertContext);

  useEffect(() => {
    setLoading(true);
    (
      API.graphql(
        graphqlOperation(getContactsInvitationsForCampaign, {
          campaignId: invitation.campaignId,
          pageSize,
          pageNumber,
        }),
      ) as Promise<any>
    ).then(({ data: { getContactsInvitationsForCampaign } }) => {
      const { contactInvitations = [], count = 0 } = getContactsInvitationsForCampaign || {};
      const sortedContacts = contactInvitations.sort((a: any, b: any) =>
        a.firstName.toLocaleLowerCase().localeCompare(b.firstName.toLocaleLowerCase()),
      );
      setContacts(sortedContacts);
      setPagesCount(Math.ceil(count / pageSize));
      setLoading(false);
    }).catch(showAlert);
  }, [invitation, pageSize, pageNumber]);

  const resend = async (invitationId: string) => {
    (API.graphql(graphqlOperation(resendInvitation, { invitationId })) as Promise<any>)
      .then(({ data: { resendInvite } }) => {
        setVisible(true);
        setTimeout(() => setVisible(false), 3000);
      })
      .catch(showAlert);
  };

  const columns: TableProps.ColumnDefinition<ContactInvitation>[] = [
    {
      id: 'ListInvitationContact',
      header: 'Contact',
      cell: (i) => (
        <Link variant='primary'>
          {i.firstName} {i.lastName}
        </Link>
      ),
    },
    {
      id: 'ListInvitationAccountName',
      header: 'Account',
      cell: (i) => i.accountName,
    },
    {
      id: 'ListInvitationStatus',
      header: 'Status',
      cell: (i) => {
        let type: StatusIndicatorProps.Type = 'in-progress';
        if (i.status === InvitationStatus.ACCEPTED) type = 'success';
        if (i.status === InvitationStatus.DECLINED) type = 'error';
        return <StatusIndicator type={type}>{i.status}</StatusIndicator>;
      },
    },
    {
      id: 'ListInvitationActions',
      header: 'Actions',
      cell: (i) =>
        i.status === InvitationStatus.NO_RESPONSE || i.status === InvitationStatus.READ ? (
          <Button variant='primary' onClick={() => resend(i.invitationId)}>
            Resend
          </Button>
        ) : null,
    },
  ];

  return (
    <Modal visible={!!invitation} size='max' onDismiss={onDismiss} header={`Invitations to ${invitation.eventName}`}>
      <Alert onDismiss={() => setVisible(false)} visible={visible} dismissAriaLabel='Close alert' dismissible type='success'>
        Email Sent Successfully
      </Alert>
      <Table
        wrapLines
        loadingText='Loading...'
        loading={loading}
        columnDefinitions={columns}
        items={contacts}
        empty={<Box variant='p'>Empty</Box>}
        pagination={
          <Pagination
            currentPageIndex={pageNumber}
            pagesCount={pagesCount}
            disabled={loading}
            onChange={(e) => setPageNumber(e.detail.currentPageIndex)}
          />
        }
      />
    </Modal>
  );
};

export default InvitationModal;
