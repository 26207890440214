import React from 'react';
import { AppLayout } from "@amzn/awsui-components-react";
import Navigation from "../Navigation/Navigation";
import HelpPanel from '../HelpPanel/HelpPanel';
import Routes from '../../routes/Routes';
import { calendarRef } from '../../routes/AdminRoutes'

// Calendar has a bug that doesn't resize when Navigation opens/closes, so this forces resizing
const refreshCalendar = () => {
  const calendarApi = (calendarRef.current as any)?.getApi();
  calendarApi?.next();
  calendarApi?.prev();
};

const AdminLayout = () => (
  <AppLayout
    onNavigationChange={refreshCalendar}
    navigation={<Navigation />}
    disableBodyScroll={true}
    disableContentPaddings={true}
    headerSelector='#navbar'
    footerSelector='#footer'
    content={<Routes admin />}
    tools={<HelpPanel />}
  />
);

export default AdminLayout;
