import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableProps,
  Link,
  Header,
  Button,
  SpaceBetween,
} from "@amzn/awsui-components-react/polaris";
import { useRouteMatch, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { Campaign } from "../../../types";
import { listCampaigns } from "../../../graphql/queries";
import ListView from '../../../components/ListView/ListView';
import AlertContext from '../../../contexts/AlertContext';

const CampaignsListView = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [campaigns, setCampaigns] = React.useState<
  Campaign[]
>([]);
  const [loading, setLoading] = useState(true);
  const showAlert = useContext(AlertContext);

  useEffect(() => {
    (API.graphql(graphqlOperation(listCampaigns)) as Promise<any>).then(
      ({ data: { getCampaigns } }) => {
        setCampaigns(getCampaigns);
        setLoading(false);
      }
    ).catch(showAlert);
  }, []);

  const columns: TableProps.ColumnDefinition<Campaign>[] = [
    {
      id: "CampaignName",
      header: "Name",
      cell: (e) => (
        <Link
          variant="primary"
          onFollow={() => history.push(`${path}/${e.Id}`)}
        >
          {e.Name}
        </Link>
      ),
    },
    {
      id: "CreationDate",
      header: "CreationDate",
      cell: (e) => (
          e.CreationDate
      ),
    },
  ];

  const Buttons = (
    <Header
      counter={loading ? '' : `(${campaigns?.length})`}
      actions={
        <SpaceBetween size="xs" direction="horizontal">
          <Button
            variant="primary"
            onClick={() => history.push(`${path}/create`)}
          >
            Create Invite
          </Button>
        </SpaceBetween>
      }
    >
      Invites
    </Header>
  );
  return <ListView
  list={campaigns}
  columns={columns}
  loading={loading}
  header={Buttons}
/>
};

export default CampaignsListView;
