import * as AWS from 'aws-sdk';

export type CampaignInvite = {
  campaignId: string;
  campaignName: string;
  eventId: string;
  eventName: string;
  date: string;
}

export interface InvitationRecipient {
  name: string;
  accountName: string;
  status: InvitationStatus;
}

export enum InvitationStatus {
  NO_RESPONSE = 'No Response',
  READ = 'READ',
  ACCEPTED = 'Accepted',
  DECLINED = 'Declined',
  SELF_REGISTERED = 'Self Registered',
}

export type CheckboxList = {
  id: number;
  checked: boolean;
};

export type Event = {
  id: string;
  name: string;
  type: string;
  segments: string;
  personas: string;
  categories: string;
  landingPageUrl: string;
  startDate: string;
  endDate: string;
  region: string;
  level: string;
  backgroundColor?: string;
  display?: string;
  title?: string;
  start?: string;
};

export type EventForContact = {
  name: string;
  categories: string;
  startDate: string;
  status: string;
};

export type contactsForEvent = {
	salutation: string;
	firstName: string;
	lastName: string;
	email: string;
	accountName: string;
	title: string;
	status: string;
}

export type Contact = {
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  accountName: string;
  accountOwnerAlias: string;
  title: string;
  emailOptOut: boolean;
  bounces: number;
  complains: number;
};

export type ContactInvitation = {
  invitationId: string;
	salutation: string;
	firstName: string;
	lastName: string;
	email: string;
	accountName: string;
	title: string;
	status: string;
}

export interface EmailTemplate extends Partial<AWS.Pinpoint.EmailTemplateResponse> {
  Shared?: boolean;
  FriendlyName?: string;
}

export interface Campaign extends Partial<AWS.Pinpoint.CampaignResponse> {}
