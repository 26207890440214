import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import styles from "./index.module.css";
import TemplatesListView from "./TemplatesListView/TemplatesListView";
import CreateTemplatePage from "./CreateUpdateTemplate/CreateTemplatePage";


const TemplatesPage = () => {
  const { path } = useRouteMatch();

  return (
    <div className={styles.container}>
      <Switch>
        <Route exact path={path}>
          <TemplatesListView />
        </Route>
        <Route path={`${path}/:templateName`}>
          <CreateTemplatePage />
        </Route>
      </Switch>
    </div>
  );
};

export default TemplatesPage;
