import React from 'react';
import { HelpPanel as PolarisHelpPanel } from "@amzn/awsui-components-react/polaris";
import { useLocation } from 'react-router-dom';

interface HelpPanelContents {
  title: string;
  body: React.ReactNode;
}

const placeholderEvents = `
On this page you have all the events that have been approved by marketing.\n
To refine the search, you can filter the items by persona, industry or technology. The search bar allows you to search by event name.\n
You can see the participants of an event who have registered from Chronos.\n
If you are an event organizer and would like to add your event on Chronos, please contact the marketing team.`;

const placeholderContacts = `
On this page, you have access to all the contacts that have been imported into the Chronos database. You will be able to see which events the customer is participating in.\n
To add contacts, you need to export a salesforce file and import it with the same structure as the demo file.\n
To refine the search, you can filter the items by industry or by people who are opt-in or opt-out. The search bar allows you to search by email or name.\n
To update the contact, you need to re-upload the contact with all the information.`;

const placeholderAccounts = `
  We do not manage accounts in V1`;

const placeholderTemplate = `
On this page, you have access to all invitation templates.\n
You can create your own templates. If you want to modify an existing template that does not belong to you, please create a new one by copying and pasting the code of the existing one.\n
In order to organize the templates, if you create a copy of the original template please put in the name of the template "original name - your id".\n
The message body must be html code. You can use dynamic values to customize the email to each customer.\n
The search bar allows you to search by template name.`;

const placeholderCampaign = `
On this page, you have invited your customers to a marketing approved event.\n
Step 1: Choose the name you want to give to your invitation.\n
Step 2: Choose the event(s) you want to invite your customers to.\n
Step 3: Optionally, you can select an account to filter the contacts that will be displayed in the following step.\n
Step 4: Choose the customers to whom you will send the invitation.\n
Step 5: Choose the email template of the invitation.\n
Step 6: Finalize the invitation email template.\n
Step 7: Verify the information and send the email`;

const placeholderInvitations = `
On this page, you have the history of all the invitations you have sent.\n
You can see the status of the invitation for each customer.\n
`;

function DivideInParagraph(props : any) {
  const text = props.text;
  return text.split('\n').map((str : any, i: number) => <p key={'info'+i}>{str}</p>);
}

const HelpPanel = () => {
  const location = useLocation();

  const eventsPanelContents = (): HelpPanelContents => {
    return {
      title: 'Events',
      body: (
        <div>
          <DivideInParagraph text={placeholderEvents} />
        </div>
      )
    }
  }

  const contactsPanelContents = (): HelpPanelContents => {
    return {
      title: 'Contacts',
      body: (
        <div>
          <DivideInParagraph text={placeholderContacts}/>
        </div>
      )
    }
  }

  const accountsPanelContents = (): HelpPanelContents => {
    return {
      title: 'Accounts',
      body: (
        <div>
          <DivideInParagraph text={placeholderAccounts}/>
        </div>
      )
    }
  }

  const templatesPanelContents = (): HelpPanelContents => {
    return {
      title: 'Templates',
      body: (
        <div>
          <DivideInParagraph text={placeholderTemplate}/>
        </div>
      )
    }
  }

  const campaignsPanelContents = (): HelpPanelContents => {
    return {
      title: 'Campaigns',
      body: (
        <div>
          <DivideInParagraph text={placeholderCampaign}/>
        </div>
      )
    }
  }

  const invitationsPanelContents = (): HelpPanelContents => {
    return {
      title: 'Invitations',
      body: (
        <div>
          <DivideInParagraph text={placeholderInvitations}/>
        </div>
      )
    }
  }

  const getContents = (): HelpPanelContents => {
    const path = location.pathname;
    if (!path || path === '/') return eventsPanelContents();

    const pathParts = path.split('/');
    switch (pathParts[1]) {
      case 'events': return eventsPanelContents();
      case 'contacts': return contactsPanelContents();
      case 'accounts': return accountsPanelContents();
      case 'templates': return templatesPanelContents();
      case 'campaigns': return campaignsPanelContents();
      case 'invitations': return invitationsPanelContents();
      default: return eventsPanelContents();
    }
  }

  const contents = getContents();

  return (
    <PolarisHelpPanel header={<h2>{contents.title}</h2>}>
      {contents.body}
    </PolarisHelpPanel>
  );
}

export default HelpPanel;
