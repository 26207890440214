import React, { useContext } from 'react';
import { Modal, Box, SpaceBetween, Button } from '@amzn/awsui-components-react/polaris';
import { API, graphqlOperation } from 'aws-amplify';
import UserContext from '../../contexts/UserContext';
import { selfRegister } from '../../graphql/mutations';
import AlertContext from '../../contexts/AlertContext';

type Props = {
  event?: any | undefined;
  onDismiss: () => void;
};

const RegistrationModal = (props: Props) => {
  const { event, onDismiss } = props;

  const user = useContext(UserContext);
  const showAlert = useContext(AlertContext);

  const selfRegisterfn = () =>
    (
      API.graphql(
        graphqlOperation(selfRegister, {
          email: user?.attributes.email,
          eventID: event?.id,
        }),
      ) as Promise<any>
    ).catch(showAlert);

  function currentDate() {
    var myCurrentDate = new Date();
    var date = myCurrentDate.getFullYear() + '-' + (myCurrentDate.getMonth() + 1) + '-' + myCurrentDate.getDate();
    return date;
  }

  return (
    <>
      {new Date(event?.startDate) >= new Date(currentDate()) ? (
        <Modal
          visible={!!event}
          onDismiss={onDismiss}
          header={`Register to ${event?.name}`}
          footer={
            <Box float='right'>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button variant='link' onClick={onDismiss}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    selfRegisterfn();
                    window.open(event?.landingPageUrl);
                  }}
                  iconName='external'
                  variant='primary'
                >
                  Go to registration page
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          This is a "{event?.type}" type event for the region {event?.region}. The level of the presentation is {event?.level}.
        </Modal>
      ) : (
        <Modal visible={!!event} onDismiss={onDismiss} header={`The "${event?.name}" is closed`}>
          This was a "{event?.type}" type event for the region {event?.region}. The level of the presentation was {event?.level}.
        </Modal>
      )}
    </>
  );
};

export default RegistrationModal;
