import React from 'react';
import SideNavigation from '@amzn/awsui-components-react/polaris/side-navigation';
import { useHistory, useLocation } from 'react-router-dom';

const Navigation = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <SideNavigation
      activeHref={location.pathname}
      header={{ href: '#', text: 'Dashboard' }}
      onFollow={(event) => {
        event.preventDefault();
        history.push(event.detail.href);
      }}
      items={[
        { type: 'link', text: 'Create Invitations', href: '/campaigns/create' },
        { type: 'link', text: ' View Events', href: '/events' },
        { type: 'link', text: 'Manage Contacts', href: '/contacts' },
        { type: 'link', text: 'Manage Email Templates', href: '/templates' },
        /*{ type: 'link', text: 'Create Invites', href: '/campaigns' },*/
        { type: 'link', text: 'Manage Invitations', href: '/invitations' },
      ]}
    />
  );
};

export default Navigation;
