import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Container from '@amzn/awsui-components-react/polaris/container';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Event, CheckboxList } from '../../types';
import { SpaceBetween } from '@amzn/awsui-components-react';
import ParticipantModal from '../EventHistoryModal/ParticipantsModal';
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";

type Props = {
  events: Event[];
  filteringFunction: (event: Event) => boolean;
  header: React.ReactElement;
  searchBar: React.ReactElement;
};

const CalendarView = React.forwardRef((props: Props, ref: any) => {
  const { events, filteringFunction, header, searchBar } = props;
  const [chosenEvent, setChosenEvent] = useState<any | undefined>();
  const { items: filteredEvents } = useCollection(events, {
    filtering: {
      filteringFunction,
    },
  });

/** FOR FILTERING **/
  let newEvents: Event[] = [];

  function getRandomColor() {
      var letters = '0123456789ABCDEF'.split('');
      var color = '#';
      for (var i = 0; i < 6; i++ ) {
          color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
          }

  filteredEvents.forEach(element => {
    element.backgroundColor = getRandomColor();
    element.display =  "auto";
    newEvents.push(element);
  });

/** FOR FILTERING **/
  let arrayChecked: any = [];
  /* FOR FullCalendar */
  let date = new Date();
  events.forEach(event => {
    event.start = event.startDate;
    event.title = event.name;
    arrayChecked.push({id: event.id, checked: false});
  });
  /* FOR FullCalendar */

  const handleCheck = (test: any) => {
    console.log(test);
  }

  const renderEventContent = (eventInfo: any) => {
    let index = arrayChecked.findIndex((x: any) => x.id === eventInfo.event.extendedProps.id);
    return (
  <>
  <Checkbox
  onChange={({ detail }) => {
    arrayChecked[index].checked = detail.checked
      }
}
  checked={arrayChecked[0].checked}
  id={eventInfo.event.extendedProps.id}
  >
  <b>{eventInfo.event.title}</b>
  </Checkbox>
  </>
)
  };

  return (
    <Container
      header={
        <SpaceBetween size='xs' direction='vertical'>
          {header}
          {searchBar}
        </SpaceBetween>
      }
    >
    <ParticipantModal event={chosenEvent} onDismiss={() => setChosenEvent(undefined)} />
      <FullCalendar
        ref={ref}
        height='auto'
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        initialView='dayGridMonth'
        nowIndicator={true}
        editable={false}
        events={newEvents}
        eventClick={(e) => setChosenEvent(e.event._def.extendedProps)}
        //eventContent={renderEventContent} // custom render function FUTUR SELECTABLE
      />
    </Container>
  );
});


export default CalendarView;
