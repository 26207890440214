export const getContactsByNameByAccountName = `
  query getContactsByNameByAccountName($user: String!, $lastName: String!, $accountName: String, $pageSize: Int, $pageNumber: Int) {
    getContactsByNameByAccountName(user: $user, lastName: $lastName, accountName: $accountName,pageSize: $pageSize, pageNumber: $pageNumber) {
      count
      contacts {
        email
        salutation
        firstName
        lastName
        accountOwnerAlias
        accountName
        title
        emailOptOut
        bounces
        complains
      }
    }
  }
`;

export const aggregateContactsByAccountName = `
  query aggregateContactsByAccountName($user: String!) {
    aggregateContactsByAccountName(user: $user)
  }
`;

export const listEmailTemplates = `
  query listEmailTemplates {
    listEmailTemplates {
      TemplateName
      LastModifiedDate
      CreationDate
    }
  }
`;

export const getEmailTemplate = `
  query getEmailTemplate($TemplateName: String!) {
    getEmailTemplate(TemplateName: $TemplateName) {
      Arn
      CreationDate
      HtmlPart
      LastModifiedDate
      Subject
      TemplateDescription
      TemplateName
    }
  }
`;

export const listCampaigns = `
  query listCampaigns {
    getCampaigns {
      CreationDate
      Id
      LastModifiedDate
      MessageConfiguration {
        EmailMessage {
          FromAddress
        }
      }
      Name
      State {
        CampaignStatus
      }
    }
  }
`;

export const getCampaign = `
  query getCampaign($Id: String!) {
    getCampaign(Id: $Id) {
      TemplateConfiguration {
        EmailTemplate {
          Name
        }
      }
      CreationDate
      Id
      MessageConfiguration {
        EmailMessage {
          FromAddress
        }
      }
      Name
      State {
        CampaignStatus
      }
    }
  }
`;



export const getEventsForContact = `
  query getEventsForContact($email: String!, $pageSize: Int, $pageNumber: Int) {
    getEventsForContact(email: $email, pageSize: $pageSize, pageNumber: $pageNumber) {
      count
      eventForContact {
        name
        categories
        startDate
        status
      }
    }
  }
`;

export const getContactsForEvent = `
  query getContactsForEvent($eventID: String!, $user: String!, $pageSize: Int, $pageNumber: Int) {
    getContactsForEvent(eventID: $eventID, user: $user, pageSize: $pageSize, pageNumber: $pageNumber) {
      count
      contactsForEvent {
        accountName
        email
        firstName
        title
        lastName
        salutation
        status
      }
    }
  }
`;

export const getAccount = `
  query getAccount($user: String!,  $pageSize: Int, $pageNumber: Int) {
    getAccount(user: $user, pageSize: $pageSize, pageNumber: $pageNumber){
    count
    accounts {
        accountName
      }
    }
  }
`;

export const getContactsByAccount = `
  query getContactsByAccount($user: String!, $accountName: String!) {
    getContactsByAccount(user: $user, accountName: $accountName) {
      contacts {
        email
        salutation
        firstName
        lastName
        accountOwnerAlias
        accountName
        title
        emailOptOut
      }
    }
  }`;


export const getEventsByName = `
query getEventsByName($name: String!) {
  getEventsByName(name: $name) {
    id
    name
    type
    segments
    personas
    categories
    industries
    landingPageUrl
    startDate
    endDate
    region
    level
  }
}
`;

export const getCampaignsByUser = `
query getCampaignsByUser($user: String!) {
  getCampaignsByUser(user: $user) {
    campaignId
    campaignName
    date
    eventId
    eventName
  }
}
`;

export const getContactsInvitationsForCampaign = `
query getContactsInvitationsForCampaign($campaignId: String!, $pageSize: Int, $pageNumber: Int) {
  getContactsInvitationsForCampaign(campaignId: $campaignId, pageSize: $pageSize, pageNumber: $pageNumber) {
    count
    contactInvitations {
      invitationId
      salutation
      status
      email
      accountName
      title
      firstName
      lastName
    }
  }
}
`;

