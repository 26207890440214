import React, { useState, useEffect, useContext } from "react";
import { EmailTemplate } from "../../../types";
import {
  TableProps,
  Link,
  Header,
  Button,
  SpaceBetween,
  Input,
} from "@amzn/awsui-components-react/polaris";
import ListView from "../../../components/ListView/ListView";
import { useRouteMatch, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { listEmailTemplates as listEmailTemplatesQuery } from "../../../graphql/queries";
import { arraySearch } from './TemplatesSearch';
import UserContext from "../../../contexts/UserContext";
import { dynamicSort } from "../../../helpers/utils";
import AlertContext from '../../../contexts/AlertContext';

interface TemplatesListViewProps {
  selectedItems?: EmailTemplate[];
  setSelectedItems?: any;
  selectionType?: "single" | "multi" | undefined;
}


const TemplatesListView = (props: TemplatesListViewProps) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [templates, setTemplates] = useState([]);
  const [filteredtemplates, setFilteredTemplates] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const showAlert = useContext(AlertContext);

  const user = useContext(UserContext);
  const aliasCognito = user?.username;

  const refresh = () => 
      (API.graphql(graphqlOperation(listEmailTemplatesQuery)) as Promise<any>).then(
        ({ data: { listEmailTemplates } }) => {
          const listFilteredEmailTemplates = listEmailTemplates?.filter((x: any) => x.TemplateName.startsWith("private-" + aliasCognito) || x.TemplateName.startsWith("public-")) || [];
          const listSorted = listFilteredEmailTemplates.sort(dynamicSort("TemplateName"));
          setTemplates(listSorted);
          setFilteredTemplates(listSorted);
          if (props.selectedItems?.[0]) {
            props.setSelectedItems(listSorted.filter((t: EmailTemplate) => t.TemplateName === props.selectedItems?.[0]!.TemplateName));
          }
        }
      ).catch(showAlert);

  function templateNameWithoutAlias(templateName: string) {
    const [permission, alias, ...TemplateName] = templateName.split('-');
    const templateNameWithSpace = TemplateName.join('-');
    return templateNameWithSpace.replaceAll('_', ' ');
  }
  
  useEffect(() => {
    if (loading) {
      setLoading(false);
      refresh();
    }
  }, [templates, filteredtemplates, loading, props]);

  const columns: TableProps.ColumnDefinition<EmailTemplate>[] = [
    {
      id: "TemplateName",
      header: "Name",
      sortingField: "TemplateName",
      cell: (e) => (
        <Link
          variant="primary"
          onFollow={() => history.push(`${path}/${e.TemplateName}?action=edit`)}
        >
          {templateNameWithoutAlias(e.TemplateName!)}
        </Link>
      ),
    },
    {
      id: "Ownership",
      header: "Ownership",
      cell: (e) => e.TemplateName?.split('-')[0],
    },
    {
      id: "CreationDate",
      header: "Creation Date",
      cell: (e) => new Date(e.CreationDate!).toUTCString(),
    },
    {
      id: "LastModifiedDate",
      header: "Last Modified Date",
      cell: (e) => new Date(e.LastModifiedDate!).toUTCString(),
    },
  ];



  const header = (
    <Header
      counter={loading ? '' : `(${filteredtemplates.length})`}
      info={<Link target="_blank" href="https://w.amazon.com/bin/view/AWS/EMEA/SA/Specialist_Team/Specialist_Builders/Projects/Chronos/" variant='info'>Wiki</Link>}
      actions={(path.startsWith('/templates')) ? (
        <SpaceBetween size="xs" direction="horizontal">
          <Button variant='normal' iconName='refresh' onClick={() => refresh()}></Button>
          <Button
            variant="primary"
            onClick={() => history.push(`${path}/newTemplate?action=create`)}
          >
            Create template
          </Button>
        </SpaceBetween>) : undefined
      }
    >
      Templates
    </Header>
  );

  const handleOnChange = async (e: any) => {
    setSearchValue(e)

    let search = await arraySearch(templates, e);
    setFilteredTemplates(search);

  }

  const searchBar = (
    <Input type="search" value={searchValue} id="search" placeholder="Search Filter" onChange={(e) => handleOnChange(e.detail.value)}></Input>
  );


  return (

    <ListView
      list={filteredtemplates}
      selectionType={props.selectionType}
      selectedItems={props.selectedItems}
      setSelectedItems={props.setSelectedItems}
      columns={columns}
      header={header}
      loading={loading}
      searchBar={searchBar}
    />
  );
};

export default TemplatesListView;
