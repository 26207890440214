import React, { useState, useEffect, useContext } from 'react';
import { Event } from '../../types';
import { TableProps, Link, Button } from '@amzn/awsui-components-react/polaris';
import { toUiDate } from '../../helpers/utils';
import ListView from '../ListView/ListView';
import { useRouteMatch, useHistory } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import { selfRegister } from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import AlertContext from '../../contexts/AlertContext';

type Props = {
  events: Event[];
  filteringFunction?: (item: any) => boolean;
  header?: React.ReactElement;
  searchBar?: React.ReactElement;
  selectedItems?: Event[];
  setSelectedItems?: any;
  selectionType?: any;
  loading?: boolean;
  visibleColumns?: string[];
};

const CustomerEventsListView = (props: Props) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { events, filteringFunction, loading, header, searchBar, selectedItems, setSelectedItems, selectionType, visibleColumns } = props;
  const user = useContext(UserContext);
  const showAlert = useContext(AlertContext);

  const selfRegisterfn = async (event: any) => {
    try {
      await API.graphql(
        graphqlOperation(selfRegister, {
          email: user?.attributes.email,
          eventID: event?.id,
        }),
      );
    } catch (error: any) {
      showAlert(error);
    }
  };

  function currentDate(event: Event) {
    var myCurrentDate = new Date();
    var date = myCurrentDate.getFullYear() + '-' + (myCurrentDate.getMonth() + 1) + '-' + myCurrentDate.getDate();
    if (new Date(event?.startDate) >= new Date(date)) {
      return (
        <Button
          onClick={() => {
            selfRegisterfn(event);
            window.open(event?.landingPageUrl);
          }}
          iconName='external'
        >
          Register
        </Button>
      );
    }
    return 'This event is closed';
  }

  const columns: TableProps.ColumnDefinition<Event>[] = [
    {
      id: 'ListEventName',
      header: 'Event',
      sortingField: 'name',
      cell: (e) => e.name,
    },
    {
      id: 'ListEventCategory',
      header: 'Technologies',
      sortingField: 'categories',
      cell: (e) => e.categories,
    },
    {
      id: 'ListEventPersona',
      header: 'Personas',
      sortingField: 'personas',
      cell: (e) => e.personas,
    },
    {
      id: 'ListEventDate',
      header: 'Date',
      sortingField: 'startDate',
      cell: (e) => toUiDate(e.startDate),
    },
    {
      id: 'ListRegisterButton',
      header: '',
      cell: (event) => (
        <Button
          onClick={() => {
            selfRegisterfn(event);
            window.open(event.landingPageUrl);
          }}
          iconName='external'
        >
          Register
        </Button>
      ),
    },
  ];

  return (
    <ListView
      list={events}
      filteringFunction={filteringFunction}
      columns={columns}
      header={header}
      loading={loading}
      searchBar={searchBar}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      selectionType={selectionType}
      visibleColumns={visibleColumns}
      sortingColumn={columns.find((column) => column.id === 'ListEventDate')}
    />
  );
};

export default CustomerEventsListView;
