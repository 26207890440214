import React from 'react';
import styles from './SearchBar.module.css';
import { Input, MultiselectProps } from '@amzn/awsui-components-react/polaris';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';

export type SearchBarProps = {
  searchValue: string;
  onSearchChange: (value: string) => void;
  categoryOptions: MultiselectProps.Options;
  categoriesChosen: MultiselectProps.Options;
  onCategoriesChange: (categories: MultiselectProps.Options) => void;
  personaOptions: MultiselectProps.Options;
  personasChosen: MultiselectProps.Options;
  onPersonasChange: (personas: MultiselectProps.Options) => void;
  segmentOptions: MultiselectProps.Options;
  segmentsChosen: MultiselectProps.Options;
  onSegmentsChange: (segments: MultiselectProps.Options) => void;
};

const SearchBarEvents = (props: SearchBarProps) => {
  const {
    searchValue,
    onSearchChange,
    categoriesChosen,
    onCategoriesChange,
    categoryOptions, 
    personaOptions,
    personasChosen,
    onPersonasChange,
    segmentOptions,
    segmentsChosen,
    onSegmentsChange
  } = props;

  return (
    <div className={styles.filterContainer}>
      <div className={styles.filterInput}>
        <Input type='search' value={searchValue} onChange={(e) => onSearchChange(e.detail.value)} placeholder='Find' />
      </div>
      <Multiselect className={styles.filterSelect} options={categoryOptions} selectedOptions={categoriesChosen} onChange={({detail}) => onCategoriesChange(detail.selectedOptions)} placeholder='Choose Technologies' />
      <Multiselect className={styles.filterSelect} options={personaOptions} selectedOptions={personasChosen} onChange={({detail}) => onPersonasChange(detail.selectedOptions)} placeholder='Choose Personas' />
      <Multiselect className={styles.filterSelect} options={segmentOptions} selectedOptions={segmentsChosen} onChange={({detail}) => onSegmentsChange(detail.selectedOptions)} placeholder='Choose Segments' />
    </div>
  );
};

export default SearchBarEvents;
