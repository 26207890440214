import React, { useEffect, useState, useContext } from 'react';
import styles from './CampaignDetailsView.module.css';
import { useParams } from 'react-router-dom';
import {
  Header,
  SpaceBetween,
  Container,
  ColumnLayout,
  Spinner,
} from '@amzn/awsui-components-react/polaris';
import { API, graphqlOperation } from 'aws-amplify';
import { Campaign } from '../../../types';
import { getCampaign as getCampaignQuery } from '../../../graphql/queries';
import ValueWithLabel from '../../../components/ValueWithLabel/ValueWithLabel';
import AlertContext from '../../../contexts/AlertContext';

interface RouteParams {
  campaignId: string;
}

const CampaignDetailsView = () => {
  const [campaign, setCampaign] = useState<Campaign | undefined>();
  const [loading, setLoading] = useState(true);
  const showAlert = useContext(AlertContext);

  let { campaignId } = useParams<RouteParams>();

  useEffect(() => {
    (API.graphql(graphqlOperation(getCampaignQuery, { Id: campaignId })) as Promise<
      any
    >).then(({ data: { getCampaign } }) => {
      setCampaign(getCampaign);
      setLoading(false);
    }).catch(showAlert);
  }, []);

  if (!loading) {
    return (
      <div className={styles.container}>
        <SpaceBetween size="xs">
          <Header variant="h3">{campaign?.Name}</Header>
          <Container header={<Header variant="h2">Campaign summary</Header>}>
            <ColumnLayout columns={2} variant="text-grid">
              <ValueWithLabel label='Sent date'>{campaign?.CreationDate}</ValueWithLabel>
              <ValueWithLabel label='Status'>{campaign?.State?.CampaignStatus}</ValueWithLabel>
            </ColumnLayout>
          </Container>
          <Container header={<Header variant="h2">Email preview</Header>}>
            <ColumnLayout columns={2} variant="text-grid">
              <ValueWithLabel label='Template Name'>{campaign?.TemplateConfiguration?.EmailTemplate?.Name}</ValueWithLabel>
              <ValueWithLabel label='Sent By'>{campaign?.MessageConfiguration?.EmailMessage?.FromAddress}</ValueWithLabel>
            </ColumnLayout>
          </Container>
        </SpaceBetween>
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <Spinner size="large" />
      </div>
    );
  }
};

export default CampaignDetailsView;
