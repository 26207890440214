import React, { useEffect, useContext } from 'react';
import styles from './CreateCampaignPage.module.css';
import {
  Header,
  Button,
  SpaceBetween,
  Box,
  Link,
  Wizard,
  Container,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  Textarea,
} from '@amzn/awsui-components-react/polaris';
import ContactsListView from '../../ContactsPage/ContactsListView/ContactsListView';
import TemplatesListView from '../../TemplatesPage/TemplatesListView/TemplatesListView';
import { Contact, EmailTemplate, Event } from '../../../types';
import { createCampaign as createCampaignMutation, createEmailTemplate } from '../../../graphql/mutations';
import { getEmailTemplate as getEmailTemplateQuery, getEventsByName } from '../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import sanitizeHtml from 'sanitize-html';
import { useHistory } from 'react-router-dom';
import Mustache from 'mustache';
import ValueWithLabel from '../../../components/ValueWithLabel/ValueWithLabel';
import AccountListView from '../../../components/AccountListView/AccountListView';
import UserContext from '../../../contexts/UserContext';
import { currentDate, formatDate } from '../../../helpers/utils';
import EventsView from '../../../components/EventsView/EventsView';
import { isAlphaNumeric } from '../../../helpers/utils';
import AlertContext, { AlertType } from '../../../contexts/AlertContext';

const pascalizeKeys = (obj: any) => {
  const pascalized: any = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const pascalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      pascalized[pascalizedKey] = obj[key];
    }
  }
  return pascalized;
};

const sanitize = (content: string) =>
  sanitizeHtml(content, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'ul', 'li', 'h1', 'br', 'div', 'p'],
    allowedAttributes: {
      a: ['href', 'target', 'style'],
      div: ['style'],
    },
  });

const customTags = ['%pre%', '%post%'];
const [preTag, postTag] = customTags;

const finalizeEmail = (content: string = '', event: Event) => {
  const utcDatedEvent = { ...event, startDate: new Date(event.startDate).toUTCString(), endDate: new Date(event.endDate).toUTCString() };
  const newLinedContent = content.replaceAll('\n', '<br>');
  const formattedContent = Mustache.render(newLinedContent, {
    Event: pascalizeKeys(utcDatedEvent),
    User: {
      UserAttributes: {
        Salutation: preTag + 'User.UserAttributes.Salutation' + postTag,
        FirstName: preTag + 'User.UserAttributes.FirstName' + postTag,
        LastName: preTag + 'User.UserAttributes.LastName' + postTag,
      },
    },
  });
  return `
  <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </head>
      <body>
        <p>${formattedContent}</p>
        <div style="display:flex;">

          <a style="border: 0;
          text-align: center;
          display: block;
          padding:20px 40px;
          width: 150px;
          margin: 20px;
          color: #000000;
          border: 3px solid #D3D3D3;
          background-color: #ffffff;
          border-radius: 8px;
          font-family:  sans-serif;
          font-weight: 600;
          text-decoration: none;"
          target="_blank"
          href="https://chronos-pilot.com/declined.html">
            JE NE SUIS PAS INTERESSE
          </a>
          <a style="border: 0;
          text-align: center;
          display: block;
          padding:20px 40px;
          width: 150px;
          margin: 20px;
          color: #ffffff;
          background-color: #ec7211;
          border-radius: 8px;
          font-family:  sans-serif;
          font-weight: 600;
          text-decoration: none;"
          target="_blank"
          href="${event?.landingPageUrl}">
            JE M'INSCRIS
          </a>
        </div>
      </body>
    </html>
`;
};

const sendCampaigns = async (campaignBaseName: string, event: Event, contacts: Contact[], alias: string, template: EmailTemplate, content: string) => {
  const chunkSize = 100; // pinpoint has a limit of 100 per request
  const sanitizedConent = sanitize(content.replaceAll(preTag, '{{').replaceAll(postTag, '}}'));
  const emails = contacts.map((c=>c.email));
  let slicedEmails = [];

  for (let i = 0; i < emails.length; i += chunkSize) {
    const sliced = emails.slice(i, i + chunkSize);
    slicedEmails.push(sliced);
  }

  for(const [i, chunkEmails] of slicedEmails.entries()) {
      await API.graphql(
        graphqlOperation(createCampaignMutation, {
          createCampaignInput: {
            EmailList: chunkEmails,
            EventId: event.id,
            FromAddress: `${alias}@amazon.com`,
            CampaignName: campaignBaseName + (emails.length > chunkSize ? `-${i+1}` : ''),
            Subject: template.Subject,
            Body: sanitizedConent,
          },
        }),
      );
  }
}

const CreateCampaignPage = React.forwardRef(() => {
  const [visible, setVisible] = React.useState(false);
  const [template, setTemplate] = React.useState<EmailTemplate | undefined>();
  const [activeStepIndex, setActiveStepIndex] = React.useState(0);
  const [selectedContacts, setSelectedContacts] = React.useState<Contact[]>([]);
  const [selectedAccounts, setSelectedAccounts] = React.useState<string[]>([]);
  const [selectedEvent, setSelectedEvent] = React.useState<Event>();
  const [events, setEvents] = React.useState<Event[]>([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState<EmailTemplate[]>([]);
  const [finalTemplate, setFinalTemplate] = React.useState<EmailTemplate>();
  const [demoEmailContent, setDemoEmailContent] = React.useState<string>('');
  const [finalEmailContent, setFinalEmailContent] = React.useState<string>('');
  const [campaignName, setCampaignName] = React.useState<string>('');
  const [invitationNameErrorText, setInvitationNameErrorText] = React.useState('');
  const user = useContext(UserContext);
  const [alias] = user?.attributes.email.split('@');
  const showAlert = useContext(AlertContext);

  let history = useHistory();

  useEffect(() => {
    (
      API.graphql(
        graphqlOperation(getEventsByName, {
          name: '',
        }),
      ) as Promise<any>
    )
      .then(({ data: { getEventsByName } }) => {
        const filteredEvents: any[] = [];
        getEventsByName.map((x: any) => {
          if (new Date(formatDate(x.startDate)) >= new Date(currentDate())) {
            filteredEvents.push(x);
          }
        });
        setEvents(filteredEvents);
      })
      .catch(showAlert)
  }, []);

  useEffect(() => {
    if (selectedTemplate?.[0]) {
      (
        API.graphql(
          graphqlOperation(getEmailTemplateQuery, {
            TemplateName: selectedTemplate?.[0].TemplateName,
          }),
        ) as Promise<any>
      ).then(({ data: { getEmailTemplate } }) => {
        setFinalTemplate(getEmailTemplate);
        setTemplate({ ...template, HtmlPart: getEmailTemplate.HtmlPart });
        setTemplate({ ...template, Subject: getEmailTemplate.Subject });
      }).catch(showAlert);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (finalTemplate?.HtmlPart && selectedEvent) {
      try {
        const final = finalizeEmail(finalTemplate.HtmlPart, selectedEvent);
        setFinalEmailContent(final);
        const demo = Mustache.render(
          final,
          {
            User: {
              UserAttributes: {
                Salutation: '[M.]',
                FirstName: '[John]',
                LastName: '[Doe]',
              },
            },
          },
          {},
          customTags as any,
        );
        setDemoEmailContent(demo);
      } catch (error) {
        console.warn(error);
      }
    }
  }, [finalTemplate?.HtmlPart, selectedContacts, selectedEvent]);

  const submit = async () => {
    try {
      if (!campaignName) return void setInvitationNameErrorText('please provide invitation name');
      if (campaignName.length > 64) return void setInvitationNameErrorText('should not exceed 64 characters');
      if(!selectedEvent) return void showAlert({type: AlertType.Error, text: 'No Event Chosen'});
      if(!selectedContacts.length) return void showAlert({type: AlertType.Error, text: 'No Contacts Chosen'});
      if(!finalTemplate) return void showAlert({type: AlertType.Error, text: 'No Template Chosen'});
      if(selectedContacts.length > 500) return void showAlert({ type: AlertType.Error, text: 'A Maximum of 500 Contacts Allowed' });

      await sendCampaigns(campaignName, selectedEvent, selectedContacts, alias, finalTemplate, finalEmailContent);
      
      setSelectedAccounts([]);
      setSelectedContacts([]);
      setSelectedEvent(undefined);
      setSelectedTemplate([]);
      history.push({ pathname: '/invitations', state: true });
    } catch (error: any) {
     showAlert(error);
    }
  };

  const cancel = () => {
    setSelectedAccounts([]);
    setSelectedContacts([]);
    setSelectedEvent(undefined);
    setSelectedTemplate([]);
    history.push({
      pathname: '/invitations',
    });
  };
  const saveEmailTemplate = async () => {
    try {
      await API.graphql(
        graphqlOperation(createEmailTemplate, {
          input: { ...template, TemplateName: template?.TemplateName?.replaceAll(' ', '_'), Private: true },
        }),
      );
      setVisible(false);
    } catch (error: any) {
      showAlert(error);
    }
  };

  const renderedEmailComponent = (
    <Container
      header={
        <>
          <Header variant='h2'>Email Preview</Header>
          <Header variant='h3'>subject: {finalTemplate?.Subject}</Header>
        </>
      }
    >
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(demoEmailContent),
        }}
      />
    </Container>
  );

  return (
    <div className={styles.container}>
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        closeAriaLabel='Close modal'
        size='medium'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='link' onClick={() => setVisible(false)}>
                Cancel
              </Button>
              <Button variant='primary' onClick={() => saveEmailTemplate()}>
                Save
              </Button>
            </SpaceBetween>
          </Box>
        }
        header='Save as a new template'
      >
        <FormField
          label='Create a new template'
          description='This will create a new template based on the one you have selected.'
          errorText={isAlphaNumeric(template?.TemplateName || '') ? 'only alphanumeric input allowed' : ''}
        >
          <Input
            onChange={(event) => {
              setTemplate({ ...template, TemplateName: event.detail.value });
            }}
            value={template?.TemplateName || ''}
          />
        </FormField>
      </Modal>
      <Wizard
        i18nStrings={{
          stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
          cancelButton: 'Cancel',
          previousButton: 'Previous',
          nextButton: 'Next',
          submitButton: 'Send invitation',
          optional: 'optional',
        }}
        onSubmit={() => submit()}
        onCancel={() => cancel()}
        onNavigate={({ detail }) => setActiveStepIndex(detail.requestedStepIndex)}
        activeStepIndex={activeStepIndex}
        steps={[
          {
            title: 'Event selection',
            isOptional: false,
            info: (
              <Link
                target='_blank'
                href='https://w.amazon.com/bin/view/AWS/EMEA/SA/Specialist_Team/Specialist_Builders/Projects/Chronos/'
                variant='info'
              >
                Wiki
              </Link>
            ),
            description: 'Select the event you want to invite your clients to',
            content: (
              <Container>
                <EventsView onEventSelection={setSelectedEvent} />
              </Container>
            ),
          },
          {
            title: 'Account selection',
            isOptional: true,
            info: (
              <Link
                target='_blank'
                href='https://w.amazon.com/bin/view/AWS/EMEA/SA/Specialist_Team/Specialist_Builders/Projects/Chronos/'
                variant='info'
              >
                Wiki
              </Link>
            ),
            content: (
              <Container
                header={
                  <Header variant='h2' actions={<Button onClick={() => setActiveStepIndex(2)}>Skip</Button>}>
                    Account list
                  </Header>
                }
              >
                <AccountListView selectionType={'single'} selectedItems={selectedAccounts} setSelectedItems={setSelectedAccounts}></AccountListView>
              </Container>
            ),
          },
          {
            title: 'Contact selection',
            isOptional: false,
            info: (
              <Link
                target='_blank'
                href='https://w.amazon.com/bin/view/AWS/EMEA/SA/Specialist_Team/Specialist_Builders/Projects/Chronos/'
                variant='info'
              >
                Wiki
              </Link>
            ),
            content: (
              <Container>
                <ContactsListView
                  selectedAccounts={selectedAccounts}
                  setSelectedAccounts={setSelectedAccounts}
                  selectionType={'multi'}
                  selectedItems={selectedContacts}
                  setSelectedItems={setSelectedContacts}
                  visibleColumns={['ListContactName', 'ListContactValid' , 'ListContactTitle', 'ListContactAccountName', 'ListContactOptOut']}
                ></ContactsListView>
              </Container>
            ),
          },
          {
            title: 'Email template selection',
            isOptional: false,
            info: (
              <Link
                target='_blank'
                href='https://w.amazon.com/bin/view/AWS/EMEA/SA/Specialist_Team/Specialist_Builders/Projects/Chronos/'
                variant='info'
              >
                Wiki
              </Link>
            ),
            content: (
              <TemplatesListView selectionType='single' selectedItems={selectedTemplate} setSelectedItems={setSelectedTemplate}></TemplatesListView>
            ),
          },
          {
            title: 'Finalize email',
            info: (
              <Link
                target='_blank'
                href='https://w.amazon.com/bin/view/AWS/EMEA/SA/Specialist_Team/Specialist_Builders/Projects/Chronos/'
                variant='info'
              >
                Wiki
              </Link>
            ),
            content: (
              <SpaceBetween size='xs'>
                <Header
                  variant='h3'
                  actions={
                    <Button
                      onClick={() => {
                        setTemplate({ ...template, HtmlPart: finalTemplate?.HtmlPart });
                        setVisible(true);
                      }}
                    >
                      Save As
                    </Button>
                  }
                ></Header>
                <Container
                  header={
                    <>
                      <Header variant='h2'>Email Editor</Header>
                      <Header variant='h3'>subject: {finalTemplate?.Subject}</Header>
                    </>
                  }
                >
                  <Box>
                    <Textarea
                      value={finalTemplate?.HtmlPart || ''}
                      onChange={(data) => setFinalTemplate({ ...finalTemplate, HtmlPart: data.detail.value })}
                      placeholder='This is the placeholder for your email text'
                      ariaRequired
                      rows={15}
                    ></Textarea>
                  </Box>
                </Container>
                {renderedEmailComponent}
              </SpaceBetween>
            ),
          },
          {
            title: 'Review and send invitation',
            info: (
              <Link
                target='_blank'
                href='https://w.amazon.com/bin/view/AWS/EMEA/SA/Specialist_Team/Specialist_Builders/Projects/Chronos/'
                variant='info'
              >
                Wiki
              </Link>
            ),
            content: (
              <SpaceBetween size='xs'>
                <Header variant='h3' actions={<Button onClick={() => setActiveStepIndex(0)}>Edit</Button>}></Header>
                <Container
                  header={
                    <Header variant='h2'>
                      Invitation summary for
                      <span>&nbsp;{selectedEvent?.name}</span>;{' '}
                    </Header>
                  }
                >
                  <ColumnLayout columns={1} variant='text-grid'>
                    <ValueWithLabel label='Events Name'>
                      <div>{selectedEvent?.name}</div>{' '}
                    </ValueWithLabel>
                    <ValueWithLabel label='Events Description'>
                      <div>
                        For {selectedEvent?.personas} in {selectedEvent?.segments}
                      </div>{' '}
                    </ValueWithLabel>
                  </ColumnLayout>
                  <ColumnLayout columns={1} variant='text-grid'>
                    <ContactsListView
                      selectedAccounts={selectedAccounts}
                      setSelectedAccounts={setSelectedAccounts}
                      selectionType={'multi'}
                      selectedItems={selectedContacts}
                      setSelectedItems={setSelectedContacts}
                      visibleColumns={['ListContactName', 'ListContactTitle', 'ListContactAccountName', 'ListContactOptOut']}
                    ></ContactsListView>
                  </ColumnLayout>
                </Container>
                {renderedEmailComponent}
                <Container header={<Header variant='h2'>Invitation Name</Header>}>
                  <FormField
                    label='Invitation Name'
                    stretch={true}
                    className='templateName'
                    errorText={invitationNameErrorText}
                  >
                    <Input className='templateName' value={campaignName} onChange={(event) => setCampaignName(event.detail.value)} />
                  </FormField>
                </Container>
              </SpaceBetween>
            ),
          },
        ]}
      />
    </div>
  );
});

export default CreateCampaignPage;
