import React, { useState, useContext } from 'react';
import { Button, Alert } from '@amzn/awsui-components-react/polaris';
import { API, graphqlOperation } from 'aws-amplify';
import csv from 'csvtojson';
import { uploadContacts } from '../../graphql/mutations';
import UserContext from '../../contexts/UserContext';
import AlertContext, { AlertType } from '../../contexts/AlertContext';

type Props = {
  onUpload: () => void;
};

const UploadContacts = ({ onUpload }: Props) => {
  const [fileContent, setFileContent] = useState('');
  const [errorText, setErrorText] = useState('');
  const [visible, setVisible] = React.useState(false);
  const user = useContext(UserContext);
  const showAlert = useContext(AlertContext);

  return (
    <div>
      <Alert onDismiss={() => setVisible(false)} visible={visible} dismissAriaLabel='Close alert' dismissible type='error'>
        {errorText}
      </Alert>
      <input
        type='file'
        name='contactsFile'
        accept='.csv'
        onChange={async (e: any) => {
          const decoder = new TextDecoder('iso-8859-1');
          const buffer = await e.target.files[0].arrayBuffer();

          if (!e.target.files[0]?.type?.match(/csv|excel/i)) {
            setErrorText('This file type is not supported. Please upload a CSV file.');
            setVisible(true);
            return;
          }

          const contactCSV = decoder.decode(buffer);
          const commasAmount = contactCSV.split(',').length;
          const semiColonsAmount = contactCSV.split(';').length;
          const delimiter = commasAmount > semiColonsAmount ? ',' : ';';
          const parsedContacts = await csv({ delimiter, output: 'json' }).fromString(contactCSV);

          const essentialCols = ['salutation', 'firstname', 'lastname', 'email', 'accountname', 'title', 'emailoptout'];
          const nullableValues = ['firstname', 'lastname', 'email', 'accountname'];

          for (const contact of parsedContacts) {
              const cols = Object.keys(contact).map(key => key.toLowerCase().replace(/ /g, ''));
              if (!essentialCols.every(essentialCol => cols.includes(essentialCol))) {
                setErrorText('One or more columns are missing');
                setVisible(true);
                return;
              }
            for (const [col, data] of Object.entries(contact)) {
              const currentCol = col.toLowerCase().replace(/ /g, '');
              if (nullableValues.includes(currentCol) && !data) {
                setErrorText('One or more values are missing');
                setVisible(true);
                return;
              }
            }
          }
          setFileContent(contactCSV);
          setErrorText('');
          setVisible(false);
        }}
      />
      <Button
        variant='primary'
        disabled={!fileContent || !!errorText}
        onClick={() => {
          (
            API.graphql(
              graphqlOperation(uploadContacts, {
                user: user?.attributes.email,
                contactsCSV: fileContent,
              }),
            ) as Promise<any>
          )
            .then(() => onUpload())
            .then(() => showAlert({ type: AlertType.Success, text: 'contacts uploaded successfully' }))
            .catch(showAlert);
        }}
      >
        Upload
      </Button>
    </div>
  );
};

export default UploadContacts;
