import React, { useContext } from 'react';
import './Footer.css';
import { Button, Box, SpaceBetween, Link } from '@amzn/awsui-components-react/polaris';
import UserContext from '../../contexts/UserContext';

type FooterProps = {
  isAdmin?: boolean;
};

const Footer = (props: FooterProps) => {
  const user = useContext(UserContext);
  const name = user && user.attributes ? user.attributes.given_name + ' ' + user.attributes.family_name : '';

  console.log(props.isAdmin);

  return (
    <div id='footer'>
      <Box padding={{ left: 'l' }} variant='p' color='inherit' fontSize='body-m'>
        {props.isAdmin ? (
          <Link
            target='_blank'
            href='https://survey.fieldsense.whs.amazon.dev/survey/7da082a4-1130-4122-bfaa-fec1c340119f'
            color='inverted'
            fontSize='body-m'
          >
            Feedback
          </Link>
        ) : null}
      </Box>
      <SpaceBetween direction='horizontal' size='xs'>
        <Box padding={{ right: 'l' }} variant='p' color='inherit' fontSize='body-s'>
          © 2008 - 2021, Amazon Web Services, Inc. or its affiliates. All rights reserved.
        </Box>
      </SpaceBetween>
    </div>
  );
};

export default Footer;
