import * as React from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";

interface State {
  hasError: boolean;
}

export const errorBoundaryText = "Something went wrong";

/*
  Note:
    The standard React error overlay will always render in development.
    In production, only the fallback UI, defined here, will be rendered.
*/
class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Child TS error:', error);
    console.error('Child React error:', errorInfo);
  }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <StatusIndicator type="error">
            {errorBoundaryText}
          </StatusIndicator>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
