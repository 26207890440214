import React, { PropsWithChildren } from 'react';
import { Box } from '@amzn/awsui-components-react/polaris';

interface Props {
  label: string;
}

const ValueWithLabel: React.FC<PropsWithChildren<Props>> = (props) => (
  <div>
    <Box margin={{ bottom: 'xxxs' }} color="text-label">
      {props.label}
    </Box>
    <div>{props.children}</div>
  </div>
);

export default ValueWithLabel;
