import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import styles from "./index.module.css";
import EventsPage from "./EventsPage/EventsPage";
import EventDetailsPage from "./EventDetailsPage/EventDetailsPage";


const CampaignsPage = React.forwardRef((props, ref: any) => {
  const { path } = useRouteMatch();

  return (
    <div className={styles.container}>
      <Switch>
        <Route exact path={path}>
          <EventsPage />
        </Route>
        <Route path={`${path}/:eventId`}>
          <EventDetailsPage />
        </Route>
      </Switch>
    </div>
  );
});

export default CampaignsPage;
