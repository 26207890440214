import React, { useEffect, useState, useContext } from 'react';
import {TableProps} from '@amzn/awsui-components-react/polaris';
import { Contact } from '../../types';
import { API, graphqlOperation } from 'aws-amplify';
import {getAccount} from '../../graphql/queries';
import UserContext from '../../contexts/UserContext';
import ListView from '../ListView/ListView';
import AlertContext from '../../contexts/AlertContext';

export interface AccountListViewProps {
    contactList?: Contact[];
    selectedItems?: string[];
    setSelectedItems?: any;
    filteringFunction?: (item: any) => boolean;
    searchBar?: React.ReactElement;
    selectionType?: 'single' | 'multi' | undefined;
    header?: React.ReactElement;
    onNumberOfContactsUpdate?: (nContacts: number) => void;
    visibleColumns?: string[];
}

const AccountListView = (props: AccountListViewProps) => {
    const [accounts, setAccounts] = useState<Contact[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesCount, setPagesCount] = useState(10);
    const [pageSize] = useState(20);
    const user = useContext(UserContext);
    let { setSelectedItems, selectedItems,header,selectionType, visibleColumns } = props;
    const showAlert = useContext(AlertContext);

    const columns: TableProps.ColumnDefinition<Contact>[] = [
        {
            id: 'ListContactAccountName',
            header: 'Account',
            sortingField: 'AccountName',
            cell: (e) => e,
        }
];

    useEffect(() => {
        setLoading(true);
        (API.graphql(
            graphqlOperation(getAccount, {
                user: user?.attributes.email,
                // pageSize,
                // pageNumber,
            }),
        ) as Promise<any>)
            .then(({ data: { getAccount } }) => {
                const { accounts, count } = getAccount;
                const accountNames = accounts.map((account: any) => account.accountName);
                setAccounts(accountNames);
                props.onNumberOfContactsUpdate && props.onNumberOfContactsUpdate(count);
                setPagesCount(Math.ceil(count / pageSize));
                setLoading(false);
            })
            .catch(showAlert);
    }, [pageSize, pageNumber, user]);





    const filteringFunction = (item: Contact) => {
        return true
    };


    return (
    <>
        <ListView
            list={accounts}
            columns={columns}
            filteringFunction={filteringFunction}
            header={header}
            loading={loading}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            selectionType={selectionType}
            visibleColumns={visibleColumns}
        />
    </>
);
};

export default AccountListView;
