import React, { useEffect, useState, useContext } from 'react';
import ListView from '../../components/ListView/ListView';
import {
    Header,
    Button,
    TableProps,
    Link, SpaceBetween, Alert
} from "@amzn/awsui-components-react/polaris";
import { CampaignInvite } from '../../types';
import InvitationModal from './InvitationModal';
import { toUiDate } from '../../helpers/utils';
import UserContext from '../../contexts/UserContext';
import { useHistory } from "react-router-dom";
import styles from './InvitationsPage.module.css';
import { API, graphqlOperation } from 'aws-amplify';
import { getCampaignsByUser } from '../../graphql/queries';
import { useLocation } from "react-router-dom";
import AlertContext from '../../contexts/AlertContext';

const InvitationsPage = () => {
  const location = useLocation();
  const state = location.state;
  const [visible, setVisible] = React.useState(false);
  const showAlert = useContext(AlertContext);
     
  useEffect(() => {
    if (state !== undefined){

      setVisible(true);
      const location = {
        pathname: '/invitations',
        state: undefined
      }
      history.replace(location);
    }
   }, []);
  const [invitations, setInvitations] = useState<CampaignInvite[]>([]);
  const [selectedInvitation, setSelectedInvitation] = useState<CampaignInvite|undefined>(undefined);
  const user = useContext(UserContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [ alias ] = user?.attributes.email.split('@');

  const refresh = async () => {
    try {
      setLoading(true);
      (API.graphql(
        graphqlOperation(getCampaignsByUser, {
          user: alias,
        }),
      ) as Promise<any>)
        .then(({ data: { getCampaignsByUser } }) => {
          const data = getCampaignsByUser;
          setInvitations(data);
          setLoading(false);
        })
    } catch (error: any) {
      showAlert(error);
    }
  }

  useEffect(() => {
   refresh();
  }, [alias]);

  const columns: TableProps.ColumnDefinition<CampaignInvite>[] = [
    {
      id: 'ListInvitationCampaignName',
      header: 'Name',
      cell: (i) => <p>{i.campaignName}</p>,
    },
    {
      id: 'ListInvitationEventName',
      header: 'Event',
      cell: (i) => <p>{i.eventName}</p>,
    },
    {
      id: 'ListInvitationsSentDate',
      header: 'Date of Event',
      cell: (i) => toUiDate(i.date)
    },
    {
      id: 'ListInvitationsActions',
      header: 'Status',
      cell: (i) => <Button onClick={() => {
        setSelectedInvitation(i);
      }}>View Recipients</Button>
    }
  ];



  return (
    <div className={styles.container}>
      <Alert
      onDismiss={() => setVisible(false)}
      visible={visible}
      dismissAriaLabel="Close alert"
      dismissible
      type="success"
    >
      Your invitations has been sent successfully.
    </Alert>
    {selectedInvitation ? <InvitationModal 
        invitation={selectedInvitation}
        onDismiss={() => setSelectedInvitation(undefined)}
      /> : null}
      <ListView 
        header={(
          <Header
            counter={loading ? '' : `(${invitations?.length})`}
            actions={
                <SpaceBetween size="xs" direction="horizontal">
                  <Button variant='normal' iconName='refresh' onClick={() => refresh()}></Button>
                    <Button
                        variant="primary"
                        onClick={() => history.push(`campaigns/create`)}
                    >
                        Create Invitation
                    </Button>
                </SpaceBetween>
            }
            info={<Link target="_blank" href="https://w.amazon.com/bin/view/AWS/EMEA/SA/Specialist_Team/Specialist_Builders/Projects/Chronos/" variant='info'>Wiki</Link>}
          >
            Invitations
          </Header>
        )}
        loading={loading}
        columns={columns}
        list={invitations.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())}
      />
    </div>
  );
}

export default InvitationsPage;
