import React, { useState, useEffect, useContext } from 'react';
import { Modal, Table, TableProps, Link, Pagination, StatusIndicator, Box } from '@amzn/awsui-components-react/polaris';
import { StatusIndicatorProps } from '@amzn/awsui-components-react/polaris/status-indicator';
import { contactsForEvent } from '../../types';
import { API, graphqlOperation } from 'aws-amplify';
import { getContactsForEvent } from '../../graphql/queries';
import UserContext from '../../contexts/UserContext';
import { InvitationStatus } from '../../types';
import ListView from '../../components/ListView/ListView';
import AlertContext from '../../contexts/AlertContext';

const pageSize = 5;

const columns: TableProps.ColumnDefinition<contactsForEvent>[] = [
  {
    id: 'Name',
    header: 'Name',
    cell: (e) => e.firstName + ' ' + e.lastName,
  },
  {
    id: 'ListContactAccountName',
    header: 'Account',
    cell: (e) => e.accountName,
  },
  {
    id: 'ListContactTitle',
    header: 'Title',
    sortingField: 'Title',
    cell: (e) => e.title,
  },
  {
    id: 'ListContactEmail',
    header: 'Email',
    sortingField: 'Email',
    cell: (e) => <Link variant='primary'>{e.email}</Link>,
  },
  {
    id: 'Status',
    header: 'Status',
    cell: (e) => {
      let type: StatusIndicatorProps.Type = 'in-progress';
      if (e.status === InvitationStatus.ACCEPTED) type = 'success';
      if (e.status === InvitationStatus.SELF_REGISTERED) type = 'success';
      if (e.status === InvitationStatus.DECLINED) type = 'error';
      return <StatusIndicator type={type}>{e.status}</StatusIndicator>;
    },
  },
];

type Props = {
  event?: any;
  onDismiss: () => void;
};

const ParticipantModal = (props: Props) => {
  const { event, onDismiss } = props;
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<contactsForEvent[]>([]);
  const [pagesCount, setPagesCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const user = useContext(UserContext);
  const showAlert = useContext(AlertContext);

  useEffect(() => {
    setLoading(true);
    (
      API.graphql(
        graphqlOperation(getContactsForEvent, {
          eventID: event?.id || '',
          user: user?.attributes.email,
          pageSize,
          pageNumber,
        }),
      ) as Promise<any>
    )
      .then(({ data: { getContactsForEvent } }) => {
        const { contactsForEvent, count } = getContactsForEvent || { contactsForEvent: [], count: 0 };
        setContacts(contactsForEvent);
        setPagesCount(Math.ceil(count / pageSize));
        setLoading(false);
      })
      .catch(showAlert);
  }, [event, pageSize, pageNumber]);

  return (
    <Modal visible={!!event} onDismiss={onDismiss} size='max' header={`${event?.name}'s participants`}>
      <Table
        wrapLines
        loadingText='Loading...'
        loading={loading}
        columnDefinitions={columns}
        items={contacts}
        empty={<Box variant='p'>Empty</Box>}
        pagination={
          <Pagination
            currentPageIndex={pageNumber}
            pagesCount={pagesCount}
            disabled={loading}
            onChange={(e) => setPageNumber(e.detail.currentPageIndex)}
          />}
        />
    </Modal>
  );
};

export default ParticipantModal;
