import React, { useState, useContext } from 'react';
import { Button, Alert } from '@amzn/awsui-components-react/polaris';
import { API, graphqlOperation } from 'aws-amplify';
import { uploadEvents } from '../../graphql/mutations';
import UserContext from '../../contexts/UserContext';
import AlertContext from '../../contexts/AlertContext';

type Props = {
  onUpload: () => void;
};

const UploadEvents = ({ onUpload }: Props) => {
  const [fileContent, setFileContent] = useState('');
  const [visible, setVisible] = React.useState(false);
  const user = useContext(UserContext);
  const showAlert = useContext(AlertContext);

  return (
    <div>
      <Alert
      onDismiss={() => setVisible(false)}
      visible={visible}
      dismissAriaLabel="Close alert"
      dismissible
      type="error"
    >
      This file type is not supported. Please upload a TSV file.
    </Alert>
      <input
        type='file'
        name='contactsFile'
        accept='.tsv'
        onChange={async (e: any) =>{
          if (e.target.files[0].type === "text/tab-separated-values"){
            setFileContent(await e.target.files[0].text());
          } else {
            setVisible(true);
          }
        }
        }
      />
      <Button
        variant='primary'
        disabled={!fileContent}
        onClick={() => {
          (API.graphql(
            graphqlOperation(uploadEvents, {
              user: user?.attributes.email,
              eventsTSV: fileContent,
            }),
          ) as Promise<any>)
            .then(() => onUpload())
            .catch(showAlert);
        }}
      >
        Upload
      </Button>
    </div>
  );
};

export default UploadEvents;
