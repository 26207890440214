import React , { useState }from 'react';
import { Event } from '../../../types';
import { TableProps, Link, Button } from '@amzn/awsui-components-react/polaris';
import { toUiDate } from '../../../helpers/utils';
import ListView from '../../../components/ListView/ListView';
import ParticipantModal from '../../../components/EventHistoryModal/ParticipantsModal';

type Props = {
  events: Event[];
  filteringFunction?: (item: any) => boolean;
  header?: React.ReactElement;
  searchBar?: React.ReactElement;
  selectedItems?: Event[];
  setSelectedItems?: any;
  selectionType?: any;
  loading?: boolean;
  visibleColumns?: string[];
};

const EventsListView = (props: Props) => {
  const {events, filteringFunction, loading, header, searchBar, selectedItems, setSelectedItems, selectionType, visibleColumns } = props;
  const [chosenEvent, setChosenEvent] = useState<Event | undefined>();

const columns: TableProps.ColumnDefinition<Event>[] = [
  {
    id: 'ListEventName',
    header: 'Event',
    sortingField: 'name',
    minWidth: 250,
    cell: (e) => <Link external href={`${e.landingPageUrl}`}>{e.name}</Link>,
  },
  {
    id: 'ListEventCategory',
    header: 'Technologies',
    sortingField: 'categories',
    cell: (e) => <ul>{e.categories.split(',').map( cat => <li key={'listcategory'+cat} style={{listStyleType: 'none'}}>{cat}</li>)}</ul>,
  },
  {
    id: 'ListEventPersona',
    header: 'Personas',
    sortingField: 'personas',
    cell: (e) => <ul>{e.personas.split(',').map( persona => <li key={'listpersona'+persona} style={{listStyleType: 'none'}}>{persona}</li>)}</ul>,
  },
  {
    id: 'ListEventSegments',
    header: 'Segments',
    sortingField: 'segments',
    cell: (e) => <ul>{e.segments.split(',').map( segments => <li key={'listsegments'+segments} style={{listStyleType: 'none'}}>{segments}</li>)}</ul>,
  },
  {
    id: 'ListEventDate',
    header: 'Date',
    sortingField: 'startDate',
    cell: (e) => toUiDate(e.startDate),
  },
  {
    id: 'ListEventParticipants',
    header: 'Participants',
    cell: (e) => <Button onClick={() => setChosenEvent(e)}>Show Participants</Button>,
  },
];

  return (
    <>
    <ParticipantModal event={chosenEvent} onDismiss={() => setChosenEvent(undefined)} />
    <ListView
      list={events}
      filteringFunction={filteringFunction}
      columns={columns}
      header={header}
      loading={loading}
      searchBar={searchBar}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      selectionType={selectionType}
      visibleColumns={visibleColumns}
      sortingColumn={columns.find(column => column.id === 'ListEventDate')}
    />
    </>
  );
};

export default EventsListView;
