import React, { useContext } from 'react';
import './Navbar.css';
import { Button, Box, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import UserContext from '../../contexts/UserContext';
import { Auth } from 'aws-amplify';

const textColor = '#d5dbdb';

const NavBar = () => {
  const user = useContext(UserContext);
  const name =
    user && user.attributes ? user.attributes.given_name + ' ' + user.attributes.family_name : '';

  return (
    <div id='navbar' style={{ color: textColor }}>
      <Box padding={{ left: 'l' }} variant='h1' color='inherit'>
        Chronos
      </Box>
      <SpaceBetween direction='horizontal' size='xs'>
        <Box padding={{ right: 'l' }} variant='h4' color='inherit'>
          {name}
        </Box>
        <Button variant='primary' onClick={() => Auth.signOut()}>
          Sign Out
        </Button>
      </SpaceBetween>
    </div>
  );
};

export default NavBar;
