import Amplify from 'aws-amplify';

const frontEndHost = window.location.protocol + '//' + window.location.host;

const setupAmplify = async () =>
  fetch('/config.json')
    .then((data) => data.json())
    .then(async (config) => {
      const amplifyConfig = {
        Auth: {
          mandatorySignIn: true,
          region: config.USER_POOL_REGION,
          userPoolId: config.USER_POOL_ID,
          userPoolWebClientId: config.USER_POOL_APP_ID,
          oauth: {
            domain: config.USER_POOL_DOMAIN,
            scope: ['openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: frontEndHost,
            redirectSignOut: frontEndHost,
            responseType: 'code',
            options: {
              AdvancedSecurityDataCollectionFlag: false,
            },
          },
        },
        API: {
          aws_project_region: config.APPSYNC_REGION,
          aws_appsync_graphqlEndpoint: config.APPSYNC_ENDPOINT,
          aws_appsync_region: config.APPSYNC_REGION,
          aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
        },
      };
      await Amplify.configure(amplifyConfig);
      return amplifyConfig;
    });

export default setupAmplify;
