import React, { useEffect, useState, useContext } from 'react';
import { Event } from '../../types';
import CalendarView from '../../components/CalendarView/CalendarView';
import { Tabs, Header, Button, SpaceBetween, Toggle, MultiselectProps, Link } from '@amzn/awsui-components-react/polaris';
import SearchBarEvents from '../../components/SearchBar/SearchBarEvents';
import EventsListView from '../../pages/EventsPages/EventsListView/EventsListView';
import { API, graphqlOperation } from 'aws-amplify';
import { getEventsByName } from '../../graphql/queries';
import AlertContext from '../../contexts/AlertContext';

type EventsViewProps = {
  showCalendar?: boolean;
  isAdmin?: boolean;
  onUploadHandler?: () => any;
  showParticipants?: boolean;
  onEventSelection?: any;
};

const listTab = 'ListView';

const EventsView = React.forwardRef((props: EventsViewProps, ref: any) => {
  const { isAdmin, showCalendar, onUploadHandler, showParticipants, onEventSelection } = props;

  const [checked, setChecked] = React.useState(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [activeTabId, setActiveTabId] = useState('ListView');
  const [categoriesChosen, setCategoriesChosen] = useState<MultiselectProps.Options>([]);
  const [personasChosen, setPersonasChosen] = useState<MultiselectProps.Options>([]);
  const [segmentsChosen, setSegmentsChosen] = useState<MultiselectProps.Options>([]);
  const [selectedEvents, setSelectedEvents] = useState<Event[]>([]);
  const showAlert = useContext(AlertContext);

  useEffect(() => {
    refresh(checked);
  }, [checked]);

  const refresh = async (showPast: boolean) => {
    setLoading(true);
    (
      API.graphql(
        graphqlOperation(getEventsByName, {
          name: '',
        }),
      ) as Promise<any>
    )
      .then(({ data: { getEventsByName } }) => {
        const filteredEvents: Event[] = getEventsByName.filter((event: Event) =>
          showPast ? new Date(event.startDate) < new Date() : new Date(event.startDate) >= new Date(),
        );
        setEvents(filteredEvents);
      })
      .catch(showAlert)
      .finally(() => setLoading(false));
  };

  const filteringFunction = (item: Event) => {
    const categoryExist = categoriesChosen.every((category: MultiselectProps.Option) => item.categories.includes(category.value!));
    const personaExist = personasChosen.every((persona: MultiselectProps.Option) => item.personas.includes(persona.value!));
    const segmentExist = segmentsChosen.every((segment: MultiselectProps.Option) => item.segments.includes(segment.value!));
    const searchExist = item.name.toLowerCase().includes(searchValue.toLowerCase());
    return categoryExist && personaExist && searchExist && segmentExist;
  };

  const categoryOptions = [...new Set(events.map((event) => event.categories.split(',')).flat())].sort().map((category) => ({
    label: category,
    value: category,
  }));
  const personaOptions = [...new Set(events.map((event) => event.personas.split(',')).flat())].sort().map((persona) => ({
    label: persona,
    value: persona,
  }));
  const segmentOptions = [...new Set(events.map((event) => event.segments.split(',')).flat())].sort().map((segment) => ({
    label: segment,
    value: segment,
  }));

  const searchBar = (
    <SearchBarEvents
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      categoryOptions={categoryOptions}
      categoriesChosen={categoriesChosen}
      onCategoriesChange={setCategoriesChosen}
      personaOptions={personaOptions}
      personasChosen={personasChosen}
      onPersonasChange={setPersonasChosen}
      segmentOptions={segmentOptions}
      segmentsChosen={segmentsChosen}
      onSegmentsChange={setSegmentsChosen}
    />
  );

  const header = (
    <Header
      counter={`(${events.length})`}
      info={
        <Link target='_blank' href='https://w.amazon.com/bin/view/AWS/EMEA/SA/Specialist_Team/Specialist_Builders/Projects/Chronos/' variant='info'>
          Wiki
        </Link>
      }
      actions={
        showCalendar ? (
          <SpaceBetween size='xs' direction='horizontal'>
            <div style={{ marginTop: '25%' }}>
              <Toggle
                onChange={({ detail }) => {
                  setChecked(detail.checked);
                  refresh(detail.checked);
                }}
                checked={checked}
              >
                Past Events
              </Toggle>
            </div>
            <div style={{ marginTop: '40%' }}>
              {' '}
              <Button variant='normal' iconName='refresh' onClick={() => refresh(checked)}></Button>
            </div>

            {isAdmin && onUploadHandler ? (
              <div style={{ marginTop: '40%' }}>
                <Button variant='primary' onClick={onUploadHandler}>
                  +
                </Button>
              </div>
            ) : null}
            <Tabs
              activeTabId={activeTabId}
              tabs={[
                {
                  label: 'List View',
                  id: 'ListView',
                },
                {
                  label: 'Calendar View',
                  id: 'CalendarView',
                },
              ]}
              onChange={(newId) => setActiveTabId(newId.detail.activeTabId)}
            />
          </SpaceBetween>
        ) : null
      }
    >
      Events
    </Header>
  );

  const visibleColumns = ['ListEventName', 'ListEventCategory', 'ListEventPersona', 'ListEventDate'];
  if (showParticipants) visibleColumns.push('ListEventParticipants');

  return showCalendar && activeTabId !== listTab ? (
    <CalendarView ref={ref} events={events} header={header} searchBar={searchBar} filteringFunction={filteringFunction} />
  ) : (
    <EventsListView
      events={events}
      filteringFunction={filteringFunction}
      loading={loading}
      header={header}
      searchBar={searchBar}
      visibleColumns={visibleColumns}
      selectionType={onEventSelection && 'single'}
      selectedItems={selectedEvents}
      setSelectedItems={(items: Event[]) => {
        setSelectedEvents(items);
        onEventSelection(items[0]);
      }}
    />
  );
});

export default EventsView;
