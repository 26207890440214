import React, { useState } from 'react';
import { Table, Pagination, Box, TableProps } from '@amzn/awsui-components-react/polaris';
import { useCollection } from '@amzn/awsui-collection-hooks';

type Props = {
  list: any[];
  filteringFunction?: (item: any) => boolean;
  isItemDisabled?: (item: any) => boolean;
  searchBar?: React.ReactElement;
  columns: TableProps.ColumnDefinition<any>[];
  header?: React.ReactElement;
  loading?: boolean;
  selectedItems?: any[];
  setSelectedItems?: any | undefined;
  selectionType?: 'single' | 'multi' | undefined;
  visibleColumns?: string[];
  sortingColumn?: TableProps.ColumnDefinition<any>;
};

const pageSize = 15;

const ListView = (props: Props) => {
  const { list, isItemDisabled, filteringFunction, columns, header, searchBar, loading, selectionType, selectedItems, setSelectedItems, visibleColumns, sortingColumn } = props;

  const sorting = sortingColumn ? {
    defaultState: {
      sortingColumn
    }
  } : {};

  const {
    items: filteredEvents,
    paginationProps,
    collectionProps,
  } = useCollection(list, {
    pagination: { pageSize },
    filtering: {
      empty: <Box variant='p'>Empty</Box>,
      noMatch: <Box variant='p'>No Match</Box>,
      filteringFunction,
    },
    sorting,
    selection: {},
  });

  return (
    <Table
      {...collectionProps}
      wrapLines
      isItemDisabled={isItemDisabled}
      resizableColumns
      loadingText='Loading...'
      loading={loading}
      columnDefinitions={columns}
      onSelectionChange={({ detail }) => {
        setSelectedItems(detail.selectedItems);
      }}
      selectedItems={selectedItems}
      selectionType={selectionType ? selectionType : undefined}
      items={filteredEvents}
      pagination={<Pagination {...paginationProps} />}
      filter={searchBar}
      header={header}
      visibleColumns={visibleColumns}
    />
  );
};

export default ListView;
