import React from 'react';
import styles from './SearchBar.module.css';
import { Button, Input, Select, SelectProps } from '@amzn/awsui-components-react/polaris';

export type Filters = {
  [key: string]: {
    option: SelectProps.Option;
    options: SelectProps.Options;
  };
};

export type SearchBarProps = {
  searchValue: string;
  onSearchChange: (value: string) => void;
  onFilterChange: (key: string, option: SelectProps.Option) => void;
  onSelectAll?: () => void;
  filters: Filters;
};

const SearchBar = (props: SearchBarProps) => {
  const { searchValue, onSearchChange, filters, onFilterChange, onSelectAll } = props;
  return (
    <div className={styles.filterContainer}>
      <Input className={styles.filterInput} type='search' value={searchValue} onChange={(e) => onSearchChange(e.detail.value)} placeholder='Find' />
      {Object.entries(filters).map(([key, { option, options }], i) => (
        <Select
          key={`select-filter-${key}`}
          className={styles.filterSelect}
          options={options}
          selectedOption={option}
          onChange={(e) => onFilterChange(key, e.detail.selectedOption)}
        />
      ))}
      {onSelectAll ? (
        <Button variant='primary' onClick={onSelectAll}>
          {'Select All ' + filters.accountName?.option?.value || ''}
        </Button>
      ) : null}
    </div>
  );
};

export default SearchBar;
