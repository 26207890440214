import { SelectProps, MultiselectProps } from '@amzn/awsui-components-react/polaris';

export const isAlphaNumeric = (input: string) => input.match(/[^a-zA-Z0-9 ]+/);

export const unique = (arr: any[]) =>
  arr.filter((item, i) => arr.indexOf(item) === i);

export const generateOptionsForKey = (unshift: string, key: string, list: any[]) => {
  const options = unique(list.map((item) => item[key])).map((label) => ({
    label,
  })) as SelectProps.Option[];
  options.unshift({ label: `${unshift}` });
  return options;
};

export const formatDate = function formatDate(date: string) {
  const dateWithoutHour = date.split('T')[0];
  const [yearDate, monthDate, dayDate] = dateWithoutHour.split('-');
  return [yearDate, monthDate, dayDate].join('-');
}

export const currentDate = function currentDate() {
  var myCurrentDate = new Date();
  var date = myCurrentDate.getFullYear() + '-' + (myCurrentDate.getMonth() + 1) + '-' + myCurrentDate.getDate();
  return date;
}

export const generateOptionsForKeyMulti = (displayedName: string, key: string, list: any[]) => {
  if (list.length === 0){
    const options = unique(list.map((item) => item[key]));
    const test = options.map((name) => ({label: name, value: name})) as MultiselectProps.Option[];
    test.unshift({ label: `${displayedName}`, value: `${displayedName}`});
    return test;
    }
  else{
    const filters = list.map((item) => item[key]);
    const options = unique(filters.join().split(",").map((item:any) => item));
    const test = options.map((name) => ({label: name, value: name})) as MultiselectProps.Option[];
    test.unshift({ label: `${displayedName}`, value: `${displayedName}`});
    return test;
  }
};

export const convertToSearchBarFilter = (title:string, aggregate: Object) => {
  console.log(aggregate)
  return Object.entries(aggregate)
    .map(([key, value]) => [
      key,
      value.map((val: any) => ({ label: val === null ? 'NO INDUSTRY' : val, value: val })),
    ])
    .reduce((total, [key, value]: any[]) => {
      value.unshift({ label: `${title}`, value: '' });
      total[key as string] = { option: value[0], options: value };
      return total;
    }, {} as any);
};

export const generateGuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0,
      v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const getRandomInt = (from: number, to: number) => {
  if (from === to) return from;

  if (from > to) {
    const temp = from;
    from = to;
    to = temp;
  };

  const range = (to + 1) - from;
  return Math.floor(Math.random() * Math.floor(range)) + from;
}

export const toUiDate = (isoDate: string) => {
  const dayString = (d: number) => {
    switch (d) {
      case 0: return 'Sunday';
      case 1: return 'Monday';
      case 2: return 'Tuesday';
      case 3: return 'Wednesday';
      case 4: return 'Thursday';
      case 5: return 'Friday';
      case 6: return 'Saturday';
      default: return '';
    }
  }

  const monthString = (d: number) => {
    switch (d) {
      case 0: return 'January';
      case 1: return 'February';
      case 2: return 'March';
      case 3: return 'April';
      case 4: return 'May';
      case 5: return 'June';
      case 6: return 'July';
      case 7: return 'August';
      case 8: return 'September';
      case 9: return 'October';
      case 10: return 'November';
      case 11: return 'December';
      default: return '';
    }
  }

  const timezoneString = (date: Date) => {
    const parts = date.toUTCString().split(' ');
    return parts[parts.length - 1];
  }

  const date = new Date(isoDate);
  const day = dayString(date.getDay());
  const month = monthString(date.getMonth());
  const timezone = timezoneString(date);
  // return `${day} ${date.getDate()} ${month} ${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()} ${timezone}`;
  return `${day} ${date.getDate()} ${month} ${date.getFullYear()}`;
}

export const dynamicSort = function dynamicSort(property:any) {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a:any,b:any) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}
export const dynamicSortMultiple = function dynamicSortMultiple(obj1:any, obj2:any) {
  /*
   * save the arguments object as it will be overwritten
   * note that arguments object is an array-like object
   * consisting of the names of the properties to sort by
   */
  var props = arguments;
  return function (obj1:any, obj2:any) {
      var i = 0, result = 0, numberOfProperties = props.length;
      /* try getting a different result from 0 (equal)
       * as long as we have extra properties to compare
       */
      while(result === 0 && i < numberOfProperties) {
          result = dynamicSort(props[i])(obj1, obj2);
          i++;
      }
      return result;
  }
}