import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import EventsPage from "../pages/EventsPages";
import ContactsPage from "../pages/ContactsPage/ContactsPage";
import InvitationsPage from "../pages/InvitationsPage/InvitationsPage";
import TemplatesPage from "../pages/TemplatesPage";
import CampaignsPage from "../pages/CampaignsPages";

// Calendar has a bug that doesn't resize when Navigation opens/closes, so this forces resizing
export const calendarRef = React.createRef();

const AdminRoutes = () => {
  return (
    <Switch>
      <Route path="/events">
        <EventsPage ref={calendarRef} />
      </Route>
      <Route path="/contacts">
        <ContactsPage />
      </Route>
      <Route path="/templates">
        <TemplatesPage />
      </Route>
      <Route path="/campaigns">
        <CampaignsPage />
      </Route>
      <Route exact path="/">
        <Redirect to="/campaigns/create" />
      </Route>
      <Route exact path="/invitations">
        <InvitationsPage />
      </Route>
    </Switch>
  );
};

export default AdminRoutes;
