import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import styles from "./index.module.css";
import CampaignsListView from "./CampaignsListView/CampaignsListView";
import CampaignDetailsView from "./CampaignDetailsView/CampaignDetailsView";
import CreateCampaignPage from "./CreateCampaign/CreateCampaignPage";


const CampaignsPage = React.forwardRef((props, ref: any) => {
  const { path } = useRouteMatch();

  return (
    <div className={styles.container}>
      <Switch>
        <Route exact path={path}>
          <CampaignsListView />
        </Route>
        <Route path={`${path}/create`}>
          <CreateCampaignPage />
        </Route>
        <Route path={`${path}/:campaignId`}>
          <CampaignDetailsView />
        </Route>
      </Switch>
    </div>
  );
});

export default CampaignsPage;
