import React from 'react';
import AdminRoutes from './AdminRoutes';
import CustomerRoutes from './CustomerRoutes';

type RoutesProps = {
  admin?: boolean;
};

const Routes = (props: RoutesProps) => (props.admin ? <AdminRoutes /> : <CustomerRoutes />);

export default Routes;
