import React from 'react';
import styles from './ContactsPage.module.css';
import ContactsListView from './ContactsListView/ContactsListView';

const ContactsPage = () => {
  return (
    <div className={styles.container}>
      <ContactsListView />
    </div>
  );
};

export default ContactsPage;
