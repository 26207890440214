import React, { useCallback, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { BrowserRouter } from 'react-router-dom';
import NavBar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import setupAmplify from './helpers/setupAmplify';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import UserContext from './contexts/UserContext';
import AlertContext, { AlertMsg, AlertType } from './contexts/AlertContext';
import { Spinner, Alert } from '@amzn/awsui-components-react/polaris';
import AdminLayout from './components/AdminView/AdminLayout';
import CustomerLayout from './components/CustomerView/CustomerLayout';

const ALERT_TIME = 4000;

function App() {
  const [user, setUser] = useState<CognitoUserInterface | undefined>();
  const [alert, setAlert] = useState<AlertMsg>();
  const [configLoaded, setConfigLoaded] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const login = async () => {
    let adminGroup = '';
    try {
      // Configure Amplify lib
      const amplifyConfig = await setupAmplify();

      // Extract and set admin group from configuration
      adminGroup = `${amplifyConfig.Auth.userPoolId}_FederateIdentity`;
      await setConfigLoaded(true);
    } catch (error) {
      console.error(`Failed to load config: ${error}`);
      throw error;
    }

    try {
      // Check if already logged in ?
      const user = await Auth.currentAuthenticatedUser();

      // Extract JWT token attribute to get group ownership
      const payload = await (await Auth.currentSession()).getIdToken().decodePayload();

      // Detect if part of adminGroup
      if (payload['cognito:groups'] && payload['cognito:groups'].includes(adminGroup)) {
        await setIsAdmin(true);
      }
      await setUser(user);
    } catch (error) {
      console.log(`Failed to get current user and groups:  ${JSON.stringify(error)}`);
      // If not logged in, force auth page
      Auth.federatedSignIn();
    }
  };

  useEffect(() => {
    login();
  }, []);

  const setTempAlert = useCallback((msg: AlertMsg | any) => {
    if (!msg.type) {
      msg = { type: AlertType.Error, text: 'Something Went Wrong' };
      console.error(msg);
    }
    setAlert(msg);
    setTimeout(() => setAlert(undefined), ALERT_TIME);
  }, []);

  return configLoaded && user ? (
    <UserContext.Provider value={user}>
      <AlertContext.Provider value={setTempAlert}>
        <BrowserRouter>
          <div className='awsui'>
            {alert ? (
              <Alert visible={!!alert} type={alert.type}>
                {alert.text}
              </Alert>
            ) : null}
            <NavBar />
            <ErrorBoundary>{isAdmin ? <AdminLayout /> : <CustomerLayout />}</ErrorBoundary>
            <Footer isAdmin={isAdmin} />
          </div>
        </BrowserRouter>
      </AlertContext.Provider>
    </UserContext.Provider>
  ) : (
    <div>
      <Spinner />
      <p>{configLoaded}</p>
      <p>{user?.username}</p>
    </div>
  );
}

export default App;
