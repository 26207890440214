import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import CustomerEventsPage from '../components/CustomerView/CustomerEventsPage';

const CustomerRoutes = () => {
  return (
    <Switch>
      <Route path='/events'>
        <CustomerEventsPage />
      </Route>
      <Route exact path='/'>
        <Redirect to='/events' />
      </Route>
    </Switch>
  );
};

export default CustomerRoutes;
