
export const createEmailTemplate = `mutation CreateEmailTemplate($input: createUpdateEmailTemplateInput!) {
  createEmailTemplate(input: $input) {
    Arn
    TemplateName
    CreationDate
    LastModifiedDate
    TemplateDescription
    HtmlPart
    Subject
    Private
  }
}
`;

export const uploadEvents = `
  mutation uploadEvents($user: String!, $eventsTSV: String!) {
    uploadEvents(user: $user, eventsTSV: $eventsTSV)
  }
`;

export const updateAccountOwnerAlias = `
  mutation updateAccountOwnerAlias($accountOwnerAlias: String!, $email: String!) {
    updateAccountOwnerAlias(accountOwnerAlias: $accountOwnerAlias, email: $email)
  }
`;

export const updateEmailTemplate = `mutation UpdateEmailTemplate($input: createUpdateEmailTemplateInput!) {
  updateEmailTemplate(input: $input) {
    Arn
    TemplateName
    CreationDate
    LastModifiedDate
    TemplateDescription
    HtmlPart
    Subject
  }
}
`;

export const deleteEmailTemplate = `
  mutation deleteEmailTemplate($TemplateName: String!) {
    deleteEmailTemplate(TemplateName: $TemplateName) {
      Arn
    }
  }
`;

export const createCampaign = `mutation CreateCampaign($createCampaignInput: createCampaignInput!) {
  createCampaign(createCampaignInput: $createCampaignInput) {
    Id
  }
}
`;

export const selfRegister = `mutation selfRegister($email: String!, $eventID: String!) {
  selfRegister(email: $email, eventID: $eventID)
}
`;

export const resendInvitation = `
  mutation resendInvitation($invitationId: String!) {
    resendInvitation(invitationId: $invitationId)
  }
`;

export const uploadContacts = `
  mutation uploadContacts($user: String!, $contactsCSV: String!) {
    uploadContacts(user: $user, contactsCSV: $contactsCSV)
  }
`;