import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import EventsView from '../../../components/EventsView/EventsView';
import { Modal } from '@amzn/awsui-components-react/polaris';
import UploadEvents from '../../../components/UploadEvents/UploadEvents';

const EventsPage = React.forwardRef((props, ref: any) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);

  useEffect(() => {
    login();
  }, []);

  const login = async () => {
    try {
      // Check if already logged in ?
      const user = await Auth.currentAuthenticatedUser();

      // Extract JWT token attribute to get group ownership
      const payload = await (await Auth.currentSession()).getIdToken().decodePayload();

      // Detect if part of adminGroup
      if (payload['cognito:groups'] && payload['cognito:groups'].includes('EventAdmin-group')) {
        await setIsAdmin(true);
      }
    } catch (error) {
      console.log(`Failed to get current user and groups:  ${JSON.stringify(error)}`);
      // If not logged in, force auth page
      Auth.federatedSignIn();
    }
  };

  return <>
      <Modal onDismiss={() => setModalVisible(false)} visible={modalVisible} size='medium' header='Upload Events'>
        <UploadEvents onUpload={() => setModalVisible(false)} />
      </Modal>
   <EventsView isAdmin={isAdmin} showCalendar onUploadHandler={() => setModalVisible(true)} showParticipants />
  </>

});

export default EventsPage;
