import React from 'react';
import { AlertProps } from "@amzn/awsui-components-react/polaris/alert";

export enum AlertType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

export type AlertMsg = {
  type: AlertProps.Type,
  text: String
}

const AlertContext = React.createContext<(alertMsg: AlertMsg) => void>(() => {});

export default AlertContext;
