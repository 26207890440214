import React, { useEffect, useState, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { TableProps, Link, Button, Header, Modal, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { Contact } from '../../../types';
import SearchBar, { Filters } from '../../../components/SearchBar/SearchBar';
import { API, graphqlOperation } from 'aws-amplify';
import { updateAccountOwnerAlias } from '../../../graphql/mutations';
import { getContactsByNameByAccountName, aggregateContactsByAccountName } from '../../../graphql/queries';
import { dynamicSortMultiple, convertToSearchBarFilter } from '../../../helpers/utils';
import UserContext from '../../../contexts/UserContext';
import EventHistoryModal from '../../../components/EventHistoryModal/EventHistoryModal';
import ListView from '../../../components/ListView/ListView';
import UploadContacts from '../../../components/UploadContacts/UploadContacts';
import AlertContext from '../../../contexts/AlertContext';

export interface ContactsListViewProps {
  contactList?: Contact[];
  selectedAccounts?: string[];
  setSelectedAccounts?: any;
  selectedItems?: Contact[];
  setSelectedItems?: any;
  filteringFunction?: (item: any) => boolean;
  searchBar?: React.ReactElement;
  selectionType?: 'single' | 'multi' | undefined;
  header?: React.ReactElement;
  onNumberOfContactsUpdate?: (nContacts: number) => void;
  visibleColumns?: string[];
}

const ContactsListView = (props: ContactsListViewProps) => {
  const { path } = useRouteMatch();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [nContacts, setNContacts] = React.useState<number | undefined>(undefined);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagesCount, setPagesCount] = useState(10);
  const [pageSize] = useState(20);
  const [searchValue, setSearchValue] = useState('');
  const user = useContext(UserContext);
  const [filters, setFilters] = useState<Filters>({});
  let { contactList, selectedAccounts, setSelectedAccounts, setSelectedItems, selectedItems, selectionType, visibleColumns } = props;
  const [chosenContact, setChosenContact] = useState<Contact | undefined>();
  const showAlert = useContext(AlertContext);

  const inContactsPage = path.startsWith('/contacts');

  const columns: TableProps.ColumnDefinition<Contact>[] = [
    {
      id: 'ListContactName',
      header: 'Name',
      sortingField: 'lastName',
      cell: (e) => e.firstName + ' ' + e.lastName,
    },
    {
      id: 'ListContactValid',
      header: 'Is Valid',
      cell: (e) => (!!e.bounces || !!e.complains) ? 'Not Valid' : 'Valid',
    },
    {
      id: 'ListContactAccountName',
      header: 'Account',
      sortingField: 'accountName',
      cell: (e) => e.accountName,
    },
    {
      id: 'ListContactTitle',
      header: 'Title',
      sortingField: 'title',
      cell: (e) => e.title,
    },
    {
      id: 'ListContactEmail',
      header: 'Email',
      minWidth: 140,
      sortingField: 'email',
      cell: (e) => <Link variant='primary'>{e.email}</Link>,
    },
    {
      id: 'ListContactOptOut',
      header: 'Opted Out',
      sortingField: 'emailOptOut',
      maxWidth: 30,
      cell: (e) => (e.emailOptOut ? 'Yes' : 'No'),
    },
    {
      id: 'ShowContactEvents',
      header: 'Events',
      cell: (e) => <Button onClick={() => setChosenContact(e)}>Show Registrations</Button>,
    },
    {
      id: 'DeleteContact',
      header: '',
      maxWidth: 20,
      cell: (e) => (
        <Button
          iconName='status-negative'
          variant='icon'
          onClick={() =>
            (
              API.graphql(
                graphqlOperation(updateAccountOwnerAlias, {
                  accountOwnerAlias: '',
                  email: e.email,
                }),
              ) as Promise<any>
            )
              .then(refresh)
              .catch(showAlert)
          }
        />
      ),
    },
  ];
  let contactsFinal: Contact[];

  const refresh = async () => {
    try {
      setLoading(true);
      (
        API.graphql(
          graphqlOperation(getContactsByNameByAccountName, {
            user: user?.attributes.email,
            lastName: '',
            accountName: filters.accountName?.option?.value,
            // pageSize,
            // pageNumber,
          }),
        ) as Promise<any>
      )
        .then(({ data: { getContactsByNameByAccountName } }) => {
          const { contacts, count } = getContactsByNameByAccountName;
          if (contactList) {
            contactsFinal = contactList;
          } else {
            contactsFinal = contacts;
          }
          const contactsSorted = contactsFinal.sort(dynamicSortMultiple('accountName', 'lastName'));
          setContacts(contactsSorted);
          props.onNumberOfContactsUpdate && props.onNumberOfContactsUpdate(count);
          setPagesCount(Math.ceil(count / pageSize));
          setLoading(false);
        })
        .catch(showAlert);
    } catch (error) {
      console.error(error);
      //TODO: Handle error
    }
  };

  useEffect(() => {
    (
      API.graphql(
        graphqlOperation(aggregateContactsByAccountName, {
          user: user?.attributes.email,
        }),
      ) as Promise<any>
    )
      .then(({ data: { aggregateContactsByAccountName } }) => {
        const filters = {
          accountName: aggregateContactsByAccountName,
        };
        const filterList = convertToSearchBarFilter('All Accounts', filters);

        const [selectedAccount] = selectedAccounts || [];
        if (selectedAccount) {
          const initialOption = filterList.accountName.options.find((option: any) => option.value === selectedAccount);
          setFilters({
            ...filterList,
            accountName: { ...filterList.accountName, option: initialOption },
          });
        } else {
          setFilters(filterList);
        }
      })
      .catch(showAlert);
  }, []);

  useEffect(() => {
    refresh();
  }, [filters, pageSize, pageNumber, user]);

  const { accountName } = filters;

  const filteringFunction = (item: Contact) => {
    if (searchValue.length === 0) return true;
    return Object.keys(item).some((key) => (item as any)[key].toString().toLowerCase().indexOf(searchValue.toString().toLowerCase()) > -1);
  };

  const header = (
    <Header
      counter={`(${selectedItems ? selectedItems.length : contacts.length})`}
      info={
        <Link target='_blank' href='https://w.amazon.com/bin/view/AWS/EMEA/SA/Specialist_Team/Specialist_Builders/Projects/Chronos/' variant='info'>
          Wiki
        </Link>
      }
      actions={
        inContactsPage ? (
          <SpaceBetween size='xs' direction='horizontal'>
            <Button variant='normal' iconName='refresh' onClick={() => refresh()}></Button>
            <Button variant='primary' onClick={() => setModalVisible(true)}>
              Add New Contacts
            </Button>
          </SpaceBetween>
        ) : undefined
      }
    >
      Contacts
    </Header>
  );

  const searchBar = (
    <SearchBar
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      filters={filters}
      onFilterChange={(key, option) => {
        setFilters({ ...filters, [key]: { ...filters[key], option } });
        setSelectedAccounts && setSelectedAccounts([option.value]);
      }}
      onSelectAll={
        !inContactsPage
          ? () => {
              const bounceComplainsFreeContacts = contacts.filter((contact: Contact) => !contact.bounces && !contact.complains);
              setSelectedItems(bounceComplainsFreeContacts);
            }
          : undefined
      }
    />
  );

  selectedItems = (selectedItems?.map((item) => contacts.find((contact) => contact.email === item.email)).filter(Boolean) as Contact[]) || [];

  return (
    <>
      <EventHistoryModal contact={chosenContact} onDismiss={() => setChosenContact(undefined)} />
      <Modal onDismiss={() => setModalVisible(false)} visible={modalVisible} size='medium' header='Upload Contacts'>
        <UploadContacts onUpload={() => setModalVisible(false)} />
      </Modal>
      <ListView
        list={contacts}
        isItemDisabled={(contact: Contact) => !!contact.bounces || !!contact.complains}
        filteringFunction={filteringFunction}
        columns={columns}
        header={header}
        loading={loading}
        searchBar={searchBar}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        selectionType={selectionType}
        visibleColumns={visibleColumns}
      />
    </>
  );
};

export default ContactsListView;
