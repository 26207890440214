// @ts-ignore
import React, { useContext, useEffect } from 'react';
import {
  Header,
  Button,
  SpaceBetween,
  Form,
  FormField,
  Input,
  Box,
  Modal,
  Container,
  Textarea,
} from '@amzn/awsui-components-react/polaris';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { EmailTemplate } from '../../../types';
import { getEmailTemplate } from '../../../graphql/queries';
import { createEmailTemplate, updateEmailTemplate, deleteEmailTemplate } from '../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import UserContext from '../../../contexts/UserContext';
import sanitizeHtml from 'sanitize-html';
import Mustache from 'mustache';
import { isAlphaNumeric } from '../../../helpers/utils';
import AlertContext from '../../../contexts/AlertContext';

// TODO: Migrate to https://code.amazon.com/packages/AWSMTConsoleApp/blobs/mainline/--/app/templatesV2/emailTemplateEditor.jsx

interface RouteParams {
  templateName: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const sanitize = (content: string) =>
  sanitizeHtml(content, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'ul', 'li', 'h1', 'br', 'div', 'p'],
    allowedAttributes: {
      a: ['href', 'target', 'style'],
      div: ['style'],
    },
  });

const customTags = ['%pre%', '%post%'];
const [preTag, postTag] = customTags;

const finalizeEmail = (content: string = '') => {
  const newLinedContent = content.replaceAll('\n', '<br>');
  const formattedContent = Mustache.render(newLinedContent, {
    Event: {
      Categories: 'database',
      EventLevel: '100',
      EventType: 'Virtual Event',
      LandingPageUrl: 'https://immersiondaysfrance.splashthat.com/',
      Name: 'Immersion Day Data & Analytics',
      Persona: 'C-level',
      Region: 'France',
      Segments: 'Test',
      StartDate: new Date().toUTCString(),
    },
    User: {
      UserAttributes: {
        Salutation: preTag + 'User.UserAttributes.Salutation' + postTag,
        FirstName: preTag + 'User.UserAttributes.FirstName' + postTag,
        LastName: preTag + 'User.UserAttributes.LastName' + postTag,
      },
    },
  });
  return `
  <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </head>
      <body>
        <p>${formattedContent}</p>
        <div style="display:flex;">

          <a style="border: 0;
          text-align: center;
          display: block;
          padding:20px 40px;
          width: 150px;
          margin: 20px;
          color: #000000;
          border: 3px solid #D3D3D3;
          background-color: #ffffff;
          border-radius: 8px;
          font-family:  sans-serif;
          font-weight: 600;
          text-decoration: none;">
            JE NE SUIS PAS INTERESSE
          </a>
          <a style="border: 0;
          text-align: center;
          display: block;
          padding:20px 40px;
          width: 150px;
          margin: 20px;
          color: #ffffff;
          background-color: #ec7211;
          border-radius: 8px;
          font-family:  sans-serif;
          font-weight: 600;
          text-decoration: none;">
            JE M'INSCRIS
          </a>
        </div>
      </body>
    </html>
`;
};

const CreateTemplatePage = () => {
  let { templateName } = useParams<RouteParams>();
  const action = useQuery().get('action');
  const [demoEmailContent, setDemoEmailContent] = React.useState<string>('');
  let history = useHistory();
  const [visible, setVisible] = React.useState(false);
  const [template, setTemplate] = React.useState<EmailTemplate | undefined>();
  const [templateHtmlPart, setTemplateHtmlPart] = React.useState<String>('');
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalSaveAsVisible, setModalSaveAsVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(action === 'edit' ? true : false);
  const [templateNameWithoutAlias, setTemplateNameWithoutAlias] = React.useState('');
  const showAlert = useContext(AlertContext);

  const user = useContext(UserContext);
  const alias = user?.username;
  useEffect(() => {
    if (action === 'edit' && loading && !template) {
      (
        API.graphql(
          graphqlOperation(getEmailTemplate, {
            TemplateName: templateName,
          }),
        ) as Promise<any>
      ).then(({ data: { getEmailTemplate } }) => {
        const [permission, alias, ...TemplateName] = getEmailTemplate.TemplateName.split('-');
        const templateNameWithoutSpace = TemplateName.join('-');
        setTemplateNameWithoutAlias(templateNameWithoutSpace.replaceAll('_', ' '));
        setTemplate(getEmailTemplate);
        setTemplateHtmlPart(getEmailTemplate.HtmlPart);
        setLoading(false);
      }).catch(showAlert);
    }
  }, [template, loading, action, templateName]);

  useEffect(() => {
    if (!template?.HtmlPart) return;
    try {
      const final = finalizeEmail(template.HtmlPart);
      const demo = Mustache.render(
        final,
        {
          User: {
            UserAttributes: {
              Salutation: '[M.]',
              FirstName: '[John]',
              LastName: '[Doe]',
            },
          },
        },
        {},
        customTags as any,
      );
      setDemoEmailContent(demo);
    } catch (error) {
      console.warn(error);
    }
  }, [template?.HtmlPart]);

  const renderedEmailComponent = (
    <Container header={<Header variant='h2'>Email preview</Header>}>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(demoEmailContent),
        }}
      />
    </Container>
  );

  const saveEmailTemplate = async () => {
    try {
      const graphQLMutation = action === 'create' ? createEmailTemplate : updateEmailTemplate;
      const useTemplateName = action === 'create' ? templateNameWithoutAlias : template?.TemplateName;

      await API.graphql(
        graphqlOperation(graphQLMutation, {
          input: { ...template, TemplateName: useTemplateName?.replaceAll(' ', '_'), Private: true },
        }),
      );

      history.push(`/templates`);
    } catch (error: any) {
      showAlert(error)
    }
  };

  const savePublicEmailTemplate = async () => {
    try {
      const graphQLMutation = action === 'create' ? createEmailTemplate : updateEmailTemplate;
      const useTemplateName = action === 'create' ? templateNameWithoutAlias : template?.TemplateName;

      await API.graphql(
        graphqlOperation(graphQLMutation, {
          input: { ...template, TemplateName: useTemplateName?.replaceAll(' ', '_'), Private: false },
        }),
      );

      history.push(`/templates`);
    } catch (error: any) {
      showAlert(error);
    }
  };

  const saveAsEmailTemplate = async () => {
    try {
      const graphQLMutation = createEmailTemplate;

      await API.graphql(
        graphqlOperation(graphQLMutation, {
          input: { ...template, TemplateName: templateNameWithoutAlias?.replaceAll(' ', '_'), Private: true },
        }),
      );

      history.push(`/templates`);
    } catch (error: any) {
      showAlert(error);
    }
  };

  const removeEmailTemplate = async () => {
    try {
      await API.graphql(
        graphqlOperation(deleteEmailTemplate, {
          TemplateName: template ? template.TemplateName : templateName,
        }),
      );
      history.push(`/templates`);
    } catch (error: any) {
      showAlert(error);
    }
  };

  return (
    <Form
      actions={
        <SpaceBetween direction='horizontal' size='xs'>
          <Modal
            onDismiss={() => setModalVisible(false)}
            visible={modalVisible}
            size='medium'
            header='Are you sure you want to delete this template'
            footer={
              <Box float='right'>
                <SpaceBetween direction='horizontal' size='xs'>
                  <Button variant='link' onClick={() => setModalVisible(false)}>
                    Cancel
                  </Button>

                  <Button
                    className='deleteEmailTemplate'
                    variant='primary'
                    onClick={() => {
                      setTemplate({ ...template, TemplateName: template?.TemplateName });
                      removeEmailTemplate();
                    }}
                  >
                    Delete
                  </Button>
                </SpaceBetween>
              </Box>
            }
          >
            If you delete this template, it will be deleted from the database and no user will be able to use it.
          </Modal>
          {action === 'edit' && (template?.TemplateName!.startsWith('private') || template?.TemplateName!.startsWith('public-' + alias)) ? (
            <Button className='deleteEmailTemplate' variant='normal' onClick={() => setModalVisible(true)}>
              Delete
            </Button>
          ) : undefined}
          <Button variant='link' onClick={() => history.push(`/templates`)}>
            Cancel
          </Button>
          {action === 'create' || template?.TemplateName!.startsWith('public-' + alias) ? (
            <Button
              className='saveEmailTemplate'
              variant='primary'
              onClick={() => savePublicEmailTemplate()}
              disabled={templateNameWithoutAlias && template?.HtmlPart && template?.Subject ? false : true}
            >
              {action === 'create' ? 'Create for public' : 'Update for public'}
            </Button>
          ) : undefined}
          <Modal
            onDismiss={() => setModalSaveAsVisible(false)}
            visible={modalSaveAsVisible}
            size='medium'
            header='Are you sure you want to create a private version of this template?'
            footer={
              <Box float='right'>
                <SpaceBetween direction='horizontal' size='xs'>
                  <Button variant='link' onClick={() => setModalSaveAsVisible(false)}>
                    Cancel
                  </Button>

                  <Button className='deleteEmailTemplate' variant='primary' onClick={() => saveAsEmailTemplate()}>
                    Save As
                  </Button>
                </SpaceBetween>
              </Box>
            }
          >
            <FormField
              label='Create and personalize this template'
              description='This will create a new template based on the one you have selected.'
              errorText={isAlphaNumeric(templateNameWithoutAlias) ? 'only alphanumeric input allowed' : ''}
            >
              <Input
                onChange={(event) => {
                  setTemplateNameWithoutAlias(event.detail.value);
                }}
                value={templateNameWithoutAlias || ''}
              />
            </FormField>
          </Modal>
          {templateName.startsWith('public') ? (
            <Button
              className='saveEmailTemplate'
              variant='primary'
              onClick={() => setModalSaveAsVisible(true)}
              disabled={templateNameWithoutAlias && template?.HtmlPart && template?.Subject ? false : true}
            >
              {action === 'create' ? 'Create' : 'Save As'}
            </Button>
          ) : (
            <Button
              className='saveEmailTemplate'
              variant='primary'
              onClick={() => saveEmailTemplate()}
              disabled={templateNameWithoutAlias && template?.HtmlPart && template?.Subject ? false : true}
            >
              {action === 'create' ? 'Create' : 'Update'}
            </Button>
          )}
          {action === 'edit' && template?.TemplateName!.startsWith('private-') ? (
            <Button
              className='saveEmailTemplate'
              variant='primary'
              onClick={() => setModalSaveAsVisible(true)}
              disabled={templateNameWithoutAlias && template?.HtmlPart && template?.Subject ? false : true}
            >
              Save As
            </Button>
          ) : undefined}
        </SpaceBetween>
      }
      header={<Header variant='h1'>Create Template</Header>}
    >
      <SpaceBetween direction='vertical' size='l'>
        <Container id='cache-behavior-panel' className='custom-screenshot-hide'>
          <FormField
            description='Template name'
            label='Name'
            stretch={true}
            className='templateName'
            errorText={isAlphaNumeric(templateNameWithoutAlias) ? 'only alphanumeric input allowed' : ''}
          >
            <Input
              className='templateName'
              value={templateNameWithoutAlias || ''}
              onChange={(event) => {
                setTemplateNameWithoutAlias(event.detail.value);
              }}
              disabled={action === 'edit'}
            />
          </FormField>
        </Container>

        <Container id='cache-behavior-panel' className='custom-screenshot-hide'>
          <FormField description='Subject' label='Subject' stretch={true}>
            <Input
              className='templateSubject'
              value={template?.Subject || ''}
              onChange={(event) => setTemplate({ ...template, Subject: event.detail.value })}
            />
          </FormField>
        </Container>

        <Container id='cache-behavior-panel' className='custom-screenshot-hide'>
          <FormField label='Content' stretch={true}>
            <Box>
              <Textarea
                value={
                  template?.HtmlPart ||
                  'Dear {{User.UserAttributes.Salutation}} {{User.UserAttributes.FirstName}} {{User.UserAttributes.LastName}},please attend {{Event.Name}} Thank you'
                }
                onChange={(data) => setTemplate({ ...template, HtmlPart: data.detail.value })}
                placeholder='This is the placeholder for your email text'
                ariaRequired
                rows={15}
              ></Textarea>
            </Box>
          </FormField>
        </Container>
        {renderedEmailComponent}
      </SpaceBetween>
    </Form>
  );
};

export default CreateTemplatePage;
