import React, { useState, useEffect, useContext } from 'react';
import {
  Modal,
  Table,
  TableProps,
  Link,
  Pagination,
  StatusIndicator
} from '@amzn/awsui-components-react/polaris';
import { Contact, EventForContact } from '../../types';
import { API, graphqlOperation } from "aws-amplify";
import { getEventsForContact } from "../../graphql/queries";
import { StatusIndicatorProps } from '@amzn/awsui-components-react/polaris/status-indicator';
import { InvitationStatus } from '../../types';
import { toUiDate } from '../../helpers/utils';
import ListView from '../../components/ListView/ListView';
import AlertContext from '../../contexts/AlertContext';

const pageSize = 5;

const columns: TableProps.ColumnDefinition<EventForContact>[] = [
  {
    id: 'ListEventName',
    header: 'Event',
    minWidth:400,
    cell: (e) => <Link variant='primary'>{e.name}</Link>,
  },
  {
    id: 'ListEventType',
    header: 'Type',
    cell: (e) => e.categories,
  },
  {
    id: 'ListEventDate',
    header: 'Date',
    minWidth:250,
    cell: (e) => toUiDate(e.startDate),
  },
  {
    id: 'Status',
    header: 'Status',
    cell: (e) => {
      let type: StatusIndicatorProps.Type = 'in-progress';
      if (e.status === InvitationStatus.ACCEPTED) type = 'success';
      if (e.status === InvitationStatus.SELF_REGISTERED) type = 'success';
      if (e.status === InvitationStatus.DECLINED) type = 'error';
      return <StatusIndicator type={type}>{e.status}</StatusIndicator>;
    },
  },
];

type Props = {
  contact?: Contact;
  onDismiss: () => void;
};

const EventHistoryModal = (props: Props) => {
  const { contact, onDismiss } = props;
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<EventForContact[]>([]);
  const [pagesCount, setPagesCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const showAlert = useContext(AlertContext);

  useEffect(() => {
    setLoading(true);
    (API.graphql(
      graphqlOperation(getEventsForContact, {
        email: contact?.email || '',
        pageSize,
        pageNumber,
      }),
    ) as Promise<any>)
      .then(({ data: { getEventsForContact } }) => {
        const { eventForContact = [], count = 0 } = getEventsForContact || {};
        setEvents(eventForContact);
        setPagesCount(Math.ceil(count / pageSize));
        setLoading(false);
      })
      .catch(showAlert);
  }, [contact, pageSize, pageNumber]);



  return (
    <Modal
      visible={!!contact}
      onDismiss={onDismiss}
      size='max'
      header={`${contact?.firstName} ${contact?.lastName}'s Events`}
    >
      <ListView
        list={events}
        columns={columns}
        loading={loading}
      />

    </Modal>
  );
};

export default EventHistoryModal;
