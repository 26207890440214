import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getEventById, getColorForType } from '../../../fixtures/events';
import { Event } from '../../../types';
import { toUiDate } from '../../../helpers/utils';
import {
  Header,
  SpaceBetween,
  Container,
  Spinner,
  TextContent,
  Button,
} from '@amzn/awsui-components-react/polaris';
import ValueWithLabel from '../../../components/ValueWithLabel/ValueWithLabel';
import styles from './EventDetailsPage.module.css';

interface RouteParams {
  eventId: string;
}

const EventDetailsPage = () => {
  const { eventId } = useParams<RouteParams>();
  const [event, setEvent] = useState<Event|undefined>(undefined);

  useEffect(() => {
    setEvent(getEventById(eventId));
  }, [eventId]);

  if (!event) return (<Spinner />);

  return (
    <Container>
{/*       <SpaceBetween size='m'>
        <div className={styles.headerRow}>
          <div className={styles.headerTitle}>
            <Header variant='h1'>
              {event.title}
            </Header>
          </div>
          <div className={styles.typeCircle} style={{ background: getColorForType(event.type) }}/>
          <div className={styles.typeText}>
            <TextContent>
              <h5>{event.type}</h5>
            </TextContent>
          </div>
        </div>
        <TextContent>
          {toUiDate(event.start)}
        </TextContent>
        <TextContent>
          {event.description}
        </TextContent>
        <SpaceBetween direction='horizontal' size='xxl'>
          <div className={styles.kvp}><ValueWithLabel label='Organizer'>{event.organizer}</ValueWithLabel></div>
          <div className={styles.kvp}><ValueWithLabel label='Available Seats'>{event.seats.toString()}</ValueWithLabel></div>
          <div className={styles.kvp}><ValueWithLabel label='Available Seats'>{event.level.toString()}</ValueWithLabel></div>
        </SpaceBetween>
        <Button 
          variant='primary' 
          onClick={() => {
            const win = window.open(event.link, '_blank');
            if (!win) return;
            win.focus();
          }}
        >
          Register
        </Button>
      </SpaceBetween> */}
    </Container>
  )
}

export default EventDetailsPage;
